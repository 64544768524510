import cx from 'classnames';
import { forwardRef } from 'react';
import { useScrollingContext } from '@context/ScrollingContext/ScrollingContext';
import styles from './StickyContainer.module.scss';

interface StickyContainerProps {
  children: React.ReactNode;
  withBackground?: boolean;
  withFullWidthBottomShadow?: boolean;
  className?: string;
  hasLeftNav?: boolean;
}
type RefType = HTMLDivElement | null;

export const StickyContainer = forwardRef<RefType, StickyContainerProps>(
  (
    { children, withBackground = false, withFullWidthBottomShadow = false, className, hasLeftNav },
    ref,
  ) => {
    const { isScrolling } = useScrollingContext();

    return (
      <div
        className={cx([
          styles.container,
          { [styles.background]: withBackground },
          { [styles.fullWidthBottomShadow]: withFullWidthBottomShadow && isScrolling },
          { [styles.static_container]: !isScrolling },
          { [styles.leftNav_container]: hasLeftNav && isScrolling },
          className,
        ])}
        ref={ref}
      >
        {children}
      </div>
    );
  },
);

StickyContainer.displayName = 'StickyContainer';
