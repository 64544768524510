import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { AD_BLOCK_DIMENSIONS } from '@commons/dfp';
import { AdBlock } from '@components/AdBlock/AdBlock';
import { StickyToHeaderContainer } from '@components/StickyToHeaderContainer/StickyToHeaderContainer';
import { useScrollingContext } from '@context/ScrollingContext/ScrollingContext';
import { CanonicalLink } from '@components/CanonicalLink/CanonicalLink';
import { ProductListChannelProvider } from '@modules/ga/context/channel';
import { ProductListLocationProvider } from '@modules/ga/context/location';
import { useLocalization } from '@hooks/useLocalization';
import { StandingOrderAlert } from '@components/StandingOrderAlert/StandingOrderAlert';
import { HomePageShopBy } from './components/HomePageShopBy/HomePageShopBy';
import { HomePageFeed } from './components/HomePageFeed/HomePageFeed';
import styles from './HomePage.module.scss';

export const HomePage = () => {
  const [isExpandedShopBy, setIsExpandedShopBy] = useState(false);
  const { isScrolling } = useScrollingContext();
  const { t } = useLocalization();

  useEffect(() => {
    setIsExpandedShopBy(!isScrolling);
  }, [isScrolling]);

  return (
    <>
      <CanonicalLink />
      <ProductListChannelProvider channel={'homepage'}>
        <ProductListLocationProvider location="homepage">
          <AdBlock
            adBlockId="DFPSystemMessage"
            adBlockDimensions={{ desktop: AD_BLOCK_DIMENSIONS.L, mobile: AD_BLOCK_DIMENSIONS.M }}
          />
          <StandingOrderAlert />
          <AdBlock
            adBlockId="HPFullTopBar"
            adBlockDimensions={{ desktop: AD_BLOCK_DIMENSIONS.L, mobile: AD_BLOCK_DIMENSIONS.M }}
            collapseConfig={{
              collapseEnabled: true,
              heights: { dekstop: 85, mobile: 85 },
            }}
          />
          <AdBlock
            adBlockId="HPMainTopBar"
            adBlockDimensions={{ desktop: AD_BLOCK_DIMENSIONS.L, mobile: AD_BLOCK_DIMENSIONS.M }}
          />
          <AdBlock
            adBlockId="HPMob01"
            adBlockDimensions={{ desktop: AD_BLOCK_DIMENSIONS.L, mobile: AD_BLOCK_DIMENSIONS.M }}
          />
          <AdBlock
            adBlockId="HPMob02"
            adBlockDimensions={{ desktop: AD_BLOCK_DIMENSIONS.L, mobile: AD_BLOCK_DIMENSIONS.M }}
          />
          <AdBlock
            adBlockId="HPMob03"
            adBlockDimensions={{
              desktop: AD_BLOCK_DIMENSIONS.L,
              mobile: AD_BLOCK_DIMENSIONS.M,
            }}
          />
          <StickyToHeaderContainer
            className={styles.sticky_container}
            withBackground={isExpandedShopBy}
            withFullWidthBottomShadow={isExpandedShopBy}
          >
            <div className={styles.shop_by_container}>
              <HomePageShopBy
                isSticky={isScrolling}
                isExpanded={isExpandedShopBy}
                setIsExpanded={setIsExpandedShopBy}
              />
            </div>
          </StickyToHeaderContainer>
          <Typography variant="h1" sx={visuallyHidden}>
            {t('common:homePage')}
          </Typography>
          <HomePageFeed />
          <AdBlock
            adBlockId="HPMob04"
            adBlockDimensions={{ desktop: AD_BLOCK_DIMENSIONS.L, mobile: AD_BLOCK_DIMENSIONS.M }}
          />
        </ProductListLocationProvider>
      </ProductListChannelProvider>
    </>
  );
};
