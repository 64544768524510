import { useState } from 'react';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { AddressInput, SERVICE_TYPE } from '@commons/deliveryAddresses';
import { accountAdapter } from '@adapters/accountAdapter';
import { useEditDeliveryAddress } from '@hooks/deliveryAddress/useEditDeliveryAddress';
import { useLocalization } from '@hooks/useLocalization';
import { useAccountAlertContext } from '@context/AccountAlertContext';
import { ALERT_TYPES } from '@commons/account';
import { responseErrorHandler } from '@utils/responseErrorHandler';
import { CorporateAddressForm } from '@components/CorporateAddressForm/CorporateAddressForm';
import { openAddCorporateAddressModal } from '@modules/modals/events/addCorporateAddressModal';
import { openAddResidentialAddressModal } from '@modules/modals/events/addResidentialAddressModal';
import { mapSaveAddressError } from '@utils/addressForm';
import { ResidentialAddressForm } from '@components/ResidentialAddressForm/ResidentialAddressForm';
import { fireSelectDeliveryAddressEvent } from '@modules/ga/events/ecommerce/select-delivery-address/selectDeliveryAddressCreator';
import { EditAddressModalProps } from './EditAddressModal.types';
import styles from './EditAddressModal.module.scss';

export const EditAddressModal = ({
  open,
  onClose,
  initialValues,
  addressId,
  onServiceTypeErrorClick,
}: EditAddressModalProps) => {
  const { t } = useLocalization();
  const { getShortDeliveryAddress } = accountAdapter();
  const [editAddress] = useEditDeliveryAddress();
  const DEFAULT_FORM_VALUES = getShortDeliveryAddress(initialValues);
  const title = t('account:delivery.addressModal.mainTitle.edit');
  const [submitError, setSubmitError] = useState<string | null>(null);

  const { dispatchAlert } = useAccountAlertContext();

  const onCompleted = (message: string) => {
    dispatchAlert(ALERT_TYPES.SUCCESS, message);
  };

  const handleFormSubmit = (form: AddressInput) => {
    setSubmitError(null);
    return editAddress({
      variables: {
        id: addressId,
        input: form,
      },
      onCompleted: ({ editDeliveryAddress: { actionResult, validationErrors } }) => {
        if (!actionResult.success) {
          setSubmitError(mapSaveAddressError(validationErrors[0].error, form.address.serviceType));
        } else {
          onCompleted(t('delivery.addressSuccessfullyChanged'));
          onClose();
          fireSelectDeliveryAddressEvent();
        }
      },
      onError: (error) => responseErrorHandler(error.message, dispatchAlert),
    });
  };

  const onSuccessAddAddress = () =>
    dispatchAlert(ALERT_TYPES.SUCCESS, t('delivery.newAddressSuccessfullyAdded'));

  const handleServiceTypeErrorClick = (serviceType: SERVICE_TYPE) => {
    handleCloseModal();
    if (serviceType === SERVICE_TYPE.HOME) {
      openAddCorporateAddressModal({ onSuccess: onSuccessAddAddress, onServiceTypeErrorClick });
    } else {
      openAddResidentialAddressModal({ onSuccess: onSuccessAddAddress, onServiceTypeErrorClick });
    }
    onServiceTypeErrorClick(serviceType);
  };

  const handleCloseModal = () => {
    setSubmitError(null);
    onClose();
  };

  const formProps = {
    title,
    initialValues: DEFAULT_FORM_VALUES,
    onSubmitForm: handleFormSubmit,
    submitError: submitError,
    onServiceTypeErrorClick: handleServiceTypeErrorClick,
  };

  return (
    <ModalWindow
      className={styles.modal_window}
      open={open}
      onClose={handleCloseModal}
      isScrollable
      title={title}
    >
      {DEFAULT_FORM_VALUES.address.serviceType === SERVICE_TYPE.HOME ? (
        <ResidentialAddressForm {...formProps} />
      ) : (
        <CorporateAddressForm {...formProps} />
      )}
    </ModalWindow>
  );
};
