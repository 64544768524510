import React from 'react';
import { FormHelperText } from '@mui/material';
import { Input } from '@components/UI';
import { Button } from '@components/UI/Button/Button';
import styles from './MobileFormWrapper.module.scss';

interface MobileFormWrapperProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  onSubmit: (e: React.FormEvent) => void;
  loading: boolean;
  error: boolean;
  errorText: string;
  label: string;
  helperText: string;
  buttonLabel: string;
}

export const MobileFormWrapper = ({
  value,
  onChange,
  onBlur,
  onSubmit,
  loading,
  error,
  errorText,
  label,
  helperText,
  buttonLabel,
}: MobileFormWrapperProps) => (
  <div className={styles.wrapper}>
    <Input
      id="refer-a-friend-input"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      error={error}
      typeof="email"
      type="string"
      required
      fullWidth
    />
    <div aria-live="polite">
      <FormHelperText error={error} id="helper-text-refer-a-friend">
        {error ? errorText : helperText}
      </FormHelperText>
    </div>
    <Button onClick={onSubmit} disabled={loading} loading={loading} size="large" fullWidth>
      {buttonLabel}
    </Button>
  </div>
);
