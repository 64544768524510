import { Typography } from '@mui/material';
import { ReactNode, useState } from 'react';
import { useUpdateEffect } from 'usehooks-ts';
import { warning as Warning } from '@assets/icons/system';
import { FullPayments, Payment, PAYMENT_TYPE, PaymentsType } from '@commons/payment';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { useSetDefaultPaymentMethod } from '@hooks/payments';
import { usePaymentAvailabilities } from '@hooks/payments/usePaymentAvailabilities';
import { useLocalization } from '@hooks/useLocalization';
import { useCacheFieldsInvalidate } from '@hooks/useCacheFieldsInvalidate';
import { LIGHT_CART_CACHE_FIELD } from '@graphql/cart/queries/getLightCart';
import { CART_CACHE_FIELD } from '@graphql/cart/queries/getCart';
import { useUpdateCreditRefundEvent } from '@hooks/credits/useUpdateCreditRefundEvent';
import { useAccountPageContext } from '@features/AccountPage/context/AccountPageContext';
import { ACCOUNT_MANAGEMENT_PAGE } from '@commons/account';
import { MODAL_TITLE_ID } from '@components/Modals/Modal';
import { fireSelectPaymentEvent } from '@modules/ga/events/ecommerce/select-payment/selectPaymentCreator';
import { PaymentMethod } from './components/PaymentMethod/PaymentMethod';
import { AddPaymentMethodsButton } from './components/AddPaymentMethodsButton/AddPaymentMethodsButton';
import styles from './EditPaymentModal.module.scss';

interface EditPaymentModalProps {
  children?: ReactNode;
  error?: string;
  onClose: () => void;
  onUnlinkModalOpen: (type: PaymentsType) => void;
  onSuccessAddMethod?: () => void;
  opened: boolean;
  selectMode?: boolean;
  payments?: FullPayments;
  isModifying?: boolean;
  isCreditDebitCardOnly?: boolean;
  creditRequestId?: string;
  updateSelectedPayment?: (payment: Payment) => void;
}

export const EditPaymentModal = ({
  opened,
  onClose,
  onUnlinkModalOpen,
  onSuccessAddMethod,
  error,
  selectMode,
  payments,
  isModifying,
  isCreditDebitCardOnly,
  creditRequestId,
  updateSelectedPayment,
}: EditPaymentModalProps) => {
  const { t } = useLocalization('checkout');
  const [setDefaultPaymentMethod] = useSetDefaultPaymentMethod();
  const { data: paymentAvailabilities } = usePaymentAvailabilities();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<Payment | null | undefined>(
    payments?.defaultPayment,
  );
  const [isShownErrorMessage, setIsShownErrorMessage] = useState(false);
  const [updateCreditRefundEvent] = useUpdateCreditRefundEvent();
  const { fetchPageData } = useAccountPageContext();
  const selectedPaymentMethodId = selectedPaymentMethod?.id ?? '';
  const isExpiredPaymentSelected = payments?.defaultPayment?.isExpired ?? false;
  const selectedPaymentType = payments?.payments?.find(({ selected }) => selected)?.type;
  const paymentErrorMessage =
    (!paymentAvailabilities.showBankAccount && selectedPaymentType === PAYMENT_TYPE.ECHECK) ||
    !!error
      ? t('payment.editPaymentModal.selectValidCreditCard')
      : t('payment.editPaymentModal.selectValidPaymentMethod');
  const { clearCacheFields } = useCacheFieldsInvalidate();
  const modalTitle = isCreditDebitCardOnly
    ? t('payment.selectPaymentMethod.title')
    : t('payment.editPaymentModal.title');

  const originalSelectedPayment: Payment =
    (payments?.payments || []).filter((p) => p.id === payments?.defaultPayment?.id)[0] ?? '';

  const handleClose = (reset = false) => {
    if (reset) {
      handleSetPaymentMethod(originalSelectedPayment);
    }
    onClose();
    setIsShownErrorMessage(false);
  };

  const updateCreditRefund = () => {
    if (creditRequestId) {
      updateCreditRefundEvent({
        variables: {
          input: {
            customerCreditId: creditRequestId,
            paymentMethodId: selectedPaymentMethodId,
          },
        },
        onCompleted: () => {
          onClose();
          fetchPageData(ACCOUNT_MANAGEMENT_PAGE.CREDITS, true);
        },
      });
    }
  };

  const handleSuccess = () => {
    handleClose(false);
    if (onSuccessAddMethod) onSuccessAddMethod();

    updateCreditRefund();
    clearCacheFields(['initModifyOrderCheckout', 'orderModifications']);
  };

  const selectDefaultPaymentMethod = (payment: Payment) => {
    const isWrongBankAccount =
      !paymentAvailabilities.showBankAccount && payment?.type === PAYMENT_TYPE.ECHECK;
    const isWrongEbtCard = !paymentAvailabilities.showEbt && payment?.type === PAYMENT_TYPE.EBT;

    if (isWrongEbtCard) return handleClose(true);

    if (!payment.id || payment?.isExpired || isWrongBankAccount) {
      return setIsShownErrorMessage(true);
    }
    setDefaultPaymentMethod({
      variables: { paymentMethodId: payment.id },
      onCompleted: () => {
        clearCacheFields([LIGHT_CART_CACHE_FIELD, CART_CACHE_FIELD, 'orderModifications']);
        handleClose(false);
        fireSelectPaymentEvent(payment?.type);
        if (onSuccessAddMethod) onSuccessAddMethod();
      },
    });

    if (isCreditDebitCardOnly && creditRequestId) {
      !!payment.id ? updateCreditRefund() : onClose();
    }
  };

  useUpdateEffect(() => {
    setSelectedPaymentMethod(payments && payments?.defaultPayment);
  }, [payments?.defaultPayment?.id]);

  const handleSetPaymentMethod = (payment: Payment) => {
    setSelectedPaymentMethod(payment);
    updateSelectedPayment?.(payment);
    selectDefaultPaymentMethod(payment);
  };

  return (
    <ModalWindow
      className={styles.modal_window}
      open={opened}
      onClose={handleClose}
      labelId={MODAL_TITLE_ID.EDIT_PAYMENT}
    >
      <>
        <div className={styles.title_wrapper}>
          {isExpiredPaymentSelected ? (
            <>
              <Typography variant="h3" component="h2" id={MODAL_TITLE_ID.EDIT_PAYMENT}>
                {t('payment.editPaymentModal.yourCard')}
              </Typography>
              <Typography variant="h3">{t('payment.editPaymentModal.hasExpired')}</Typography>
            </>
          ) : (
            <Typography variant="h3" component="h2" id={MODAL_TITLE_ID.EDIT_PAYMENT}>
              {modalTitle}
            </Typography>
          )}
        </div>
        {isExpiredPaymentSelected && (
          <Typography className={styles.subtitle} variant="body" component="p">
            {t('payment.editPaymentModal.selectValidCreditCard')}
          </Typography>
        )}
        <div className={styles.payments}>
          {payments?.payments &&
            payments?.payments?.map((p) => {
              if (p.type === PAYMENT_TYPE.EBT && !paymentAvailabilities.showEbt) return;
              if (p.type === PAYMENT_TYPE.ECHECK && isCreditDebitCardOnly) return;

              return (
                <PaymentMethod
                  key={p.id}
                  id={p.id}
                  type={p.type}
                  email={p.email}
                  eWalletID={p.eWalletID}
                  accountNumber={p.accountNumber}
                  expiration={p.expiration}
                  value={selectedPaymentMethodId}
                  onChange={() => handleSetPaymentMethod(p)}
                  onClose={handleClose}
                  onUnlinkModalOpen={onUnlinkModalOpen}
                  bankAccountType={p.bankAccountType}
                  isExpired={p.isExpired}
                  selectMode={selectMode}
                  isModifying={isModifying}
                  isCreditDebitCardOnly={isCreditDebitCardOnly}
                  isSinglePayment={payments.payments.length === 1}
                />
              );
            })}
        </div>
        <div className={styles.error_wrapper} aria-live="polite">
          {(isShownErrorMessage || !!error) && (
            <>
              <Warning className={styles.icon} width={12} height={12} />
              <Typography className={styles.error_message} variant="body">
                {paymentErrorMessage}
              </Typography>
            </>
          )}
        </div>

        <AddPaymentMethodsButton
          hasPaypalWallet={payments?.hasPaypalWallet as boolean}
          hasVenmoWallet={payments?.hasVenmoWallet as boolean}
          paymentAvailabilities={paymentAvailabilities}
          onSuccess={handleSuccess}
          isCreditDebitCardOnly={isCreditDebitCardOnly}
        />
      </>
    </ModalWindow>
  );
};
