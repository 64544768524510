import { useEffect, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { useThirdPartyScriptsEnabled } from '@hooks/useThirdPartyScriptsEnabled';
import { useDFPBanner } from '@hooks/useDFPBanner';
import { AD_BLOCK_DIMENSIONS, AD_BLOCK_COLLAPSE_TIME } from '@commons/dfp';
import { Pair } from '@api';
import styles from './DFPBlock.module.scss';

interface DFPBlockProps {
  adBlockId: string;
  targeting?: Pair[];
  adBlockDimensions?: {
    desktop: AD_BLOCK_DIMENSIONS;
    mobile: AD_BLOCK_DIMENSIONS;
  };
  collapseConfig?: {
    collapseEnabled: boolean;
    collapsTime?: number;
    heights?: {
      dekstop: number;
      mobile: number;
    };
  };
  onCollapse?: () => void;
  onAdBlockRender?: (isVisible: boolean) => void;
  updateBannerStatus?: (isLoading: boolean) => void;
}

const defaultDimensions = { desktop: AD_BLOCK_DIMENSIONS.XXM, mobile: AD_BLOCK_DIMENSIONS.S };

const DFPBlock = ({
  adBlockId,
  targeting,
  adBlockDimensions = defaultDimensions,
  onAdBlockRender,
  collapseConfig,
  onCollapse,
  updateBannerStatus,
}: DFPBlockProps) => {
  const { bannerProps, isEmpty, isVisible, scriptStatus, isAdsLoading } = useDFPBanner({
    adBlockId,
    targeting,
  });

  useEffect(() => {
    if (isEmpty) {
      onCollapse?.();
    }
  }, [isEmpty, onCollapse]);

  useEffect(() => {
    if (!isAdsLoading) {
      updateBannerStatus?.(false);
    }
  }, [isAdsLoading]);

  useEffect(() => {
    onAdBlockRender?.(isVisible);
  }, [isVisible, onAdBlockRender]);

  useEffect(() => {
    let timerId: ReturnType<typeof setTimeout>;
    const collapsTime = collapseConfig?.collapsTime ?? AD_BLOCK_COLLAPSE_TIME;
    if (onCollapse) {
      timerId = setTimeout(() => {
        if (isEmpty || scriptStatus !== 'ready') {
          onCollapse();
        }
      }, collapsTime);
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [isEmpty, isVisible, onCollapse, collapseConfig, scriptStatus]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={cx(styles.ad_block_container, { [styles.bottom]: !isAdsLoading })}>
      <div
        {...bannerProps}
        className={cx(
          { [styles.ad_block]: !isAdsLoading },
          styles[`desktop_${adBlockDimensions.desktop}`],
          styles[`mobile_${adBlockDimensions.mobile}`],
        )}
      />
    </div>
  );
};

const DFPBlockWrapper = (props: DFPBlockProps) => {
  const enabled = useThirdPartyScriptsEnabled();

  if (!enabled) return null;

  return <DFPBlock {...props} />;
};

const DFPBlockDelayed = (props: DFPBlockProps) => {
  const router = useRouter();
  const [canBeRendered, setCanBeRendered] = useState(false);
  const [visible, setVisible] = useState(true);
  const [isBannerLoading, setIsBannerLoading] = useState(true);
  const timerId = useRef<ReturnType<typeof setTimeout>>();
  const containerRef = useRef<HTMLDivElement>(null);
  const enabled = useThirdPartyScriptsEnabled();

  useEffect(() => {
    timerId.current = setTimeout(() => {
      setCanBeRendered(true);
    }, 4000);

    return () => {
      clearTimeout(timerId.current);
    };
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      setCanBeRendered(true);
      clearTimeout(timerId.current);
    };

    router.events?.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events?.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  useEffect(() => {
    if (!isBannerLoading && containerRef.current && props.collapseConfig?.heights) {
      containerRef.current?.style.setProperty(
        '--mobile-container-height',
        `${props.collapseConfig.heights.mobile}px`,
      );
      containerRef.current?.style.setProperty(
        '--desktop-container-height',
        `${props.collapseConfig.heights.dekstop}px`,
      );
    }
  }, [props, isBannerLoading]);

  if (!enabled) return null;

  const updateBannerStatus = (isLoading: boolean) => {
    setIsBannerLoading(isLoading);
  };

  const collapseContainer = () => {
    return visible ? (
      <div
        className={cx(styles.ad_block_wrapper, styles.desktop, styles.mobile)}
        ref={containerRef}
      >
        <DFPBlockWrapper
          {...props}
          onCollapse={() => {
            setVisible(false);
          }}
          updateBannerStatus={updateBannerStatus}
        />
      </div>
    ) : null;
  };

  const container = () => {
    return props.collapseConfig?.collapseEnabled ? (
      <>{collapseContainer()}</>
    ) : (
      <DFPBlockWrapper {...props} updateBannerStatus={updateBannerStatus} />
    );
  };

  return canBeRendered ? <>{container()}</> : null;
};

export { DFPBlockDelayed as DFPBlock };
