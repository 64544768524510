import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { sendGtmEvent } from '@modules/ga/gtm-event';

export const selectDeliveryAddressHandler = () => {
  sendGtmEvent(
    GtmCustomEvent({
      /* eslint-disable @typescript-eslint/naming-convention */
      event: 'address_selected',
      event_name: 'address_selected_click',
      ua_action: 'Delivery Address Selected',
    }),
  );
};
