import { Typography } from '@mui/material';
import Trans from 'next-translate/Trans';
import { useLocalization } from '@hooks/useLocalization';
import { Title } from '@features/AccountPage/components/Title';
import { Alert } from '@components/UI';
import styles from './EmptyPaymentState.module.scss';

export const EmptyPaymentState = () => {
  const { t } = useLocalization('account');

  return (
    <div>
      <Title className={styles.title}>{t('payments.pageTitles.payments')}</Title>
      <Alert className={styles.alert} type="info" size="large" hasShowIcon isFullWidth>
        <div className={styles.text_container}>
          <Typography component="p" variant="body">
            <Trans
              i18nKey="account:payments.emptyState.securePaymentInfo"
              components={{ span: <Typography component="span" className={styles.bold} /> }}
            />
          </Typography>
          <Typography component="p" variant="body">
            {t('payments.emptyState.updatePaymentMethods')}
          </Typography>
        </div>
      </Alert>
    </div>
  );
};
