import { ApolloError } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import isUndefined from 'lodash/isUndefined';
import { Grid } from '@components/UI/Grid/Grid';
import { Button } from '@components/UI/Button/Button';
import { useLocalization, useModal, useSetDefaultDeliveryAddress } from '@hooks/index';
import { useDeleteDeliveryAddress } from '@hooks/deliveryAddress/useDeleteDeliveryAddress';
import { useAccountAlertContext } from '@context/AccountAlertContext';
import { ALERT_TYPES } from '@commons/account';
import { AccountAlert } from '@features/AccountPage/components/AccountAlert/AccountAlert';
import {
  Address,
  DeliveryAddress,
  SERVICE_TYPE,
  ShortDeliveryAddress,
} from '@commons/deliveryAddresses';
import { AUTH_ERROR_MESSAGE, DELETE_DELIVERY_ADDRESS_ERROR } from '@constants/errorCodes';
import { AccountPageLoader } from '@features/AccountPage/components/AccountPageLoader/AccountPageLoader';
import { useModifyOrderContext } from '@context/ModifyOrderContext/ModifyOrderContext';
import { calcAddressDisabledOptionValue } from '@utils/calcAddressDisabledOptionValue';
import { Tabs } from '@components/UI/Tabs/Tabs';
import { FeatureCheck } from '@modules/featureCheck/FeatureCheck';
import { FEATURE } from '@modules/featureCheck/FeatureCheck.enums';
import { openAddResidentialAddressModal } from '@modules/modals/events/addResidentialAddressModal';
import { openAddCorporateAddressModal } from '@modules/modals/events/addCorporateAddressModal';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { useServiceType } from '@hooks/useServiceType';
import { fireSelectDeliveryAddressEvent } from '@modules/ga/events/ecommerce/select-delivery-address/selectDeliveryAddressCreator';
import { Title } from '../Title';
import { ADDRESS_TABS } from './AccountDeliveryAddresses.enums';
import { DeliveryAddCard } from './components/DeliveryCard/DeliveryAddCard';
import { DeliveryCard } from './components/DeliveryCard/DeliveryCard';
import { EditAddressModal } from './components/EditAddressModal/EditAddressModal';
import { DeliveryCardDeleteModal } from './components/AddressDeleteModal/DeliveryCardDeleteModal';
import { DeliveryCardDeleteErrorModal } from './components/AddressDeleteModal/DeliveryCardDeleteErrorModal';
import styles from './AccountDeliveryAddresses.module.scss';

interface AccountDeliveryAddressesProps {
  homeAddresses: DeliveryAddress[];
  corpAddresses: DeliveryAddress[];
  selectedAddressServiceType: SERVICE_TYPE;
  loading: boolean;
}

export const AccountDeliveryAddresses = ({
  homeAddresses,
  corpAddresses,
  selectedAddressServiceType,
  loading,
}: AccountDeliveryAddressesProps) => {
  const { getFeature } = useHeaderContext();
  const { dispatchAlert } = useAccountAlertContext();
  const { t } = useLocalization();
  const { isExpressOrder } = useModifyOrderContext();
  const {
    isOpen: isEditAddressModalOpen,
    closeModal: closeEditAddressModal,
    openModal: openEditAddressModal,
  } = useModal();
  const {
    isOpen: isDeleteAddressModalOpen,
    closeModal: closeDeleteAddressModal,
    openModal: openDeleteAddressModal,
  } = useModal();

  const {
    isOpen: isDeleteAddressErrorModalOpen,
    closeModal: closeDeleteAddressErrorModal,
    openModal: openDeleteAddressErrorModal,
  } = useModal();

  const [addressToEdit, setAddressToEdit] = useState<ShortDeliveryAddress | null>(null);
  const [addressIdToEdit, setAddressIdToEdit] = useState<string | null>(null);
  const [addressIdToDelete, setAddressIdToDelete] = useState('');
  const [activeTab, setActiveTab] = useState(ADDRESS_TABS.HOME);
  const [defaultActiveTabSelected, setDefaultActiveTabSelected] = useState(false);
  const { isCorporateServiceType } = useServiceType();

  useEffect(() => {
    if (!isUndefined(selectedAddressServiceType) && !defaultActiveTabSelected) {
      const isCorporateActive =
        isCorporateServiceType || selectedAddressServiceType === SERVICE_TYPE.CORPORATE;
      setDefaultActiveTabSelected(true);
      setActiveTab(isCorporateActive ? ADDRESS_TABS.CORPORATE : ADDRESS_TABS.HOME);
    }
  }, [selectedAddressServiceType, isCorporateServiceType, defaultActiveTabSelected]);

  const handleServiceTypeErrorClick = (serviceType: SERVICE_TYPE) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setActiveTab(serviceType === SERVICE_TYPE.HOME ? ADDRESS_TABS.CORPORATE : ADDRESS_TABS.HOME);
  };

  const handleAddClick = (serviceType: SERVICE_TYPE) => {
    setAddressToEdit(null);
    setAddressIdToEdit(null);

    const addModalParams = {
      onSuccess: () =>
        dispatchAlert(ALERT_TYPES.SUCCESS, t('delivery.newAddressSuccessfullyAdded')),
      onServiceTypeErrorClick: handleServiceTypeErrorClick,
    };
    if (serviceType === SERVICE_TYPE.HOME) {
      openAddResidentialAddressModal(addModalParams);
    } else {
      openAddCorporateAddressModal(addModalParams);
    }
  };

  const handleEditClick = useCallback(
    (address: DeliveryAddress) => {
      setAddressToEdit({
        address: address.address,
        backupInfo: address.backupInfo,
        contact: address.contact,
      });
      setAddressIdToEdit(address.address.id);
      openEditAddressModal();
    },
    [openEditAddressModal],
  );
  const [deleteAddress, { loading: deleteAddressLoading }] = useDeleteDeliveryAddress();
  const [setDefaultAddress] = useSetDefaultDeliveryAddress();

  const isSingleAddress = !!getFeature?.(FEATURE.COS)
    ? (homeAddresses?.length ?? 0) + (corpAddresses?.length ?? 0) === 1
    : homeAddresses?.length === 1;

  const onCompleted = useCallback(
    (message: string) => {
      dispatchAlert(ALERT_TYPES.SUCCESS, message);
    },
    [dispatchAlert],
  );

  const onError = useCallback(
    (error: ApolloError) => {
      if (error.message === DELETE_DELIVERY_ADDRESS_ERROR) {
        handleCloseDeleteModal();
        openDeleteAddressErrorModal();
        return;
      }

      if (error.message !== AUTH_ERROR_MESSAGE) {
        const message = error.message;
        dispatchAlert(ALERT_TYPES.ERROR, message);
      }
    },
    [dispatchAlert],
  );

  const onAddressClick = useCallback(
    (id: Address['id']) => {
      setDefaultAddress({
        variables: { id, accountTab: true },
        onCompleted: (data) => {
          if (!data?.setDefaultDeliveryAddressV2?.validationErrors?.length) {
            onCompleted(t('account:delivery.alerts.change'));
            fireSelectDeliveryAddressEvent();
          }
        },
        onError,
      });
    },
    [onCompleted, onError, setDefaultAddress, t],
  );

  const handleDeleteAddress = () => {
    deleteAddress({
      variables: { deliveryAddressId: addressIdToDelete },
      onCompleted: () => {
        onCompleted(t('account:delivery.alerts.delete'));
        handleCloseDeleteModal();
      },
      onError,
    });
  };

  const handleOpenDeleteModal = useCallback(
    (id: Address['id']) => {
      setAddressIdToDelete(id);
      openDeleteAddressModal();
    },
    [openDeleteAddressModal],
  );

  const handleCloseDeleteModal = () => {
    setAddressIdToDelete('');
    closeDeleteAddressModal();
  };

  const renderAddButtonMobile = (serviceType: SERVICE_TYPE) => (
    <Button
      className={styles.add_button_mobile}
      size="large"
      variant="outlined"
      onClick={() => handleAddClick(serviceType)}
      isFullWidth
    >
      {t('account:delivery.addNewAddress')}
    </Button>
  );

  const hasNoCosAddresses = (deliveryAddresses: DeliveryAddress[]) =>
    !!getFeature?.(FEATURE.COS) && !deliveryAddresses.length;

  const renderTabContent = (deliveryAddresses: DeliveryAddress[], serviceType: SERVICE_TYPE) => (
    <>
      <Grid rowSpacing={5} columns={{ xs: 4, sm: 6, md: 8, lg: 6 }} container>
        {deliveryAddresses?.map?.((addressListItem: DeliveryAddress) => {
          const { address, backupInfo, contact, selected, expressEnabled } = addressListItem;
          const fullName = `${contact.firstName} ${contact.lastName}`;
          return (
            <Grid key={address.id} item xs={4} sm={3} md={4} lg={3}>
              <DeliveryCard
                className={styles.list_card_item}
                details={backupInfo?.setting}
                customInstruction={contact?.instructions}
                backupInstruction={backupInfo?.contact?.instructions}
                phone={contact?.phoneNumber}
                address={address}
                isActive={selected}
                isSingleAddress={isSingleAddress}
                onClick={onAddressClick}
                onEdit={() => handleEditClick(addressListItem)}
                onDelete={handleOpenDeleteModal}
                expressEnabled={expressEnabled}
                disabled={calcAddressDisabledOptionValue(expressEnabled, isExpressOrder)}
                companyName={contact.companyName}
                fullName={fullName}
              />
            </Grid>
          );
        })}
        {hasNoCosAddresses(deliveryAddresses) && (
          <Grid item xs={12}>
            <Typography variant="h4" component="h4" className={styles.no_addresses_title}>
              {serviceType === SERVICE_TYPE.HOME
                ? t('account:preferences.delivery.noResidentialAddresses')
                : t('account:preferences.delivery.noCorporateAddresses')}
            </Typography>
          </Grid>
        )}
        <Grid className={styles.add_address_desktop} item xs={4} sm={3} md={4} lg={3}>
          <DeliveryAddCard onClick={() => handleAddClick(serviceType)} />
        </Grid>
      </Grid>
      {renderAddButtonMobile(serviceType)}
    </>
  );

  const tabsData = [
    {
      title: t('account:preferences.delivery.residentialTabTitle'),
      content: renderTabContent(homeAddresses, SERVICE_TYPE.HOME),
    },
    {
      title: t('account:preferences.delivery.corporateTabTitle'),
      content: renderTabContent(corpAddresses, SERVICE_TYPE.CORPORATE),
    },
  ];

  if (loading) {
    return <AccountPageLoader />;
  }

  const renderTitle = (addressesExists: boolean) => (
    <>
      <AccountAlert />
      <Title component="h2" className={styles.title}>
        {addressesExists
          ? t('account:preferences.delivery.addressTitle')
          : t('account:preferences.delivery.addressEmptyTitle')}
      </Title>
    </>
  );

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <FeatureCheck
          featureName={FEATURE.COS}
          enabled={() => (
            <>
              {renderTitle(!!(homeAddresses?.length || corpAddresses?.length))}
              <Tabs
                data={tabsData}
                externalState={{
                  activeTab,
                  onTabChange: (newTab) => setActiveTab(newTab as ADDRESS_TABS),
                }}
              />
            </>
          )}
          disabled={() => (
            <>
              {renderTitle(!!homeAddresses?.length)}
              {renderTabContent(homeAddresses, SERVICE_TYPE.HOME)}
            </>
          )}
        />
        <EditAddressModal
          open={isEditAddressModalOpen}
          onClose={closeEditAddressModal}
          initialValues={addressToEdit}
          addressId={addressIdToEdit}
          onServiceTypeErrorClick={handleServiceTypeErrorClick}
        />
        <DeliveryCardDeleteModal
          onDelete={handleDeleteAddress}
          onClose={handleCloseDeleteModal}
          isOpen={isDeleteAddressModalOpen}
          loading={deleteAddressLoading}
        />
        <DeliveryCardDeleteErrorModal
          isOpen={isDeleteAddressErrorModalOpen}
          onClose={closeDeleteAddressErrorModal}
        />
      </div>
    </div>
  );
};
