import { Dispatch } from 'react';
import { deleteCookie } from 'cookies-next';
import { useMutation } from '@apollo/client';
import { COOKIES_LIST } from '@commons/cookies';
import { useExpressContext } from '@context/ExpressContext/ExpressContext';
import { areItemsAddedToCartInCurrentSession } from '@graphql/variables/areItemsAddedToCartInCurrentSession';
import { fireLogoutEvent } from '@modules/ga/events/custom/logout/logoutCreator';
import { LOGOUT } from '@modules/auth/operations/mutations/Logout';
import { localStorageService, sessionStorageService, STORAGE_KEYS } from '@utils/storageService';
import { AUTH_USER_ACTIONS_TYPES, AuthUserActions } from '../context/AuthUserReducer';

interface LogoutProps {
  dispatch?: Dispatch<AuthUserActions>;
}

export const useLogout = ({ dispatch }: LogoutProps) => {
  const [logoutMutation, loading] = useMutation(LOGOUT);
  const { toggle: toggleExpress } = useExpressContext();

  const logout = async () => {
    toggleExpress(false, false);
    deleteCookie(COOKIES_LIST.SERVICE_TYPE);
    dispatch?.({ type: AUTH_USER_ACTIONS_TYPES.SET_LOGIN_MODE, payload: undefined });

    fireLogoutEvent();
    await logoutMutation({
      onError: (error) =>
        dispatch?.({ type: AUTH_USER_ACTIONS_TYPES.SET_REQUEST_ERROR, payload: error }),
      onCompleted: () => areItemsAddedToCartInCurrentSession(false),
    });
    sessionStorageService?.remove(STORAGE_KEYS.REMEMBER_ME);
    localStorageService?.remove(STORAGE_KEYS.DELIVERED_ORDERS_INFO);

    window.location.href = '/';
  };

  return { logout, isLoading: loading };
};
