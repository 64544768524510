import { useMutation, MutationHookOptions } from '@apollo/client';
import { SET_DEFAULT_DELIVERY_ADDRESS } from '@graphql/deliveryAddresses/mutations/setDefaultDeliveryAddress';
import { useCacheFieldsInvalidate } from '@hooks/useCacheFieldsInvalidate';
import { useDeliveryAddressChange } from '@graphql/variables/addressChangingVar';
import { deliveryAddressErrors } from '@graphql/variables/deliveryAddressErrors';
import { USER_DETAILS } from '@graphql/common/queries/userDetails';
import { handleServiceTypeChange } from '@utils/handleServiceTypeChange';
import { useIsServiceTypeUrlParamsPresent } from '@hooks/useIsServiceTypeUrlParamsPresent';
import { getIsCreateStandingOrderRoute } from '@helpers/common.helpers';
import { SET_SO_DEFAULT_DELIVERY_ADDRESS } from '@graphql/deliveryAddresses/mutations/setSODefaultDeliveryAddress';
import { fireChangeAddressEvent } from '@modules/ga/events/custom/change-address/changeAddressCreator';
import { fireSelectDeliveryAddressEvent } from '@modules/ga/events/ecommerce/select-delivery-address/selectDeliveryAddressCreator';
import { updateDeliveryAddressesCache } from './updateDeliveryAddressesCache';
import { FIELDS_DEPEND_ON_DEFAULT_ADDRESS } from './constants';

export const useSetDefaultDeliveryAddress = (params?: MutationHookOptions) => {
  const { setAddressChangingStateTo } = useDeliveryAddressChange();
  const { clearCacheFields } = useCacheFieldsInvalidate();
  const { isServiceTypeParamsPresent } = useIsServiceTypeUrlParamsPresent();
  const isCreateSOPage = getIsCreateStandingOrderRoute();

  const mutationQuery = isCreateSOPage
    ? SET_SO_DEFAULT_DELIVERY_ADDRESS
    : SET_DEFAULT_DELIVERY_ADDRESS;

  return useMutation(mutationQuery, {
    ...params,
    update(cache, { data: { setDefaultDeliveryAddressV2: addressesData } }) {
      updateDeliveryAddressesCache(cache, addressesData.deliveryAddresses);
    },
    refetchQueries: [USER_DETAILS],
    onCompleted: (data) => {
      if (data?.setDefaultDeliveryAddressV2?.validationErrors.length) {
        deliveryAddressErrors(data?.setDefaultDeliveryAddressV2?.validationErrors[0].error);
      }
      const selectedAddressServiceType =
        data?.setDefaultDeliveryAddressV2.deliveryAddresses.selectedAddress.address.serviceType;
      handleServiceTypeChange(
        selectedAddressServiceType,
        clearCacheFields,
        isServiceTypeParamsPresent,
      );
      clearCacheFields(FIELDS_DEPEND_ON_DEFAULT_ADDRESS);
      fireChangeAddressEvent();
      fireSelectDeliveryAddressEvent();
      setAddressChangingStateTo(false);
    },
    onError: () => {
      setAddressChangingStateTo(false);
    },
  });
};
