import { useRef, useState, FocusEvent } from 'react';
import cx from 'classnames';
import { useRouter } from 'next/router';
import { useHover } from 'usehooks-ts';
import { TileImage } from '@components/Tiles/components/TileImage/TileImage';
import { TileLabels } from '@components/Tiles/components/TileLabels/TileLabels';
import { Coupon } from '@components/UI/Coupon/Coupon';
import { AutosuggestProduct, ProductTile as IProductTile } from '@commons/product';
import { getConfigurationOptions } from '@adapters/product/getProductTile';
import { TileTagList } from '@components/Tiles/components/TiletagList/TileTagList';
import { GroupScale } from '@components/GroupScale/GroupScale';
import { TileQuantity } from '@components/Tiles/components/TileQuantity/TileQuantity';
import { TilePrice } from '@components/Tiles/components/TilePrice/TilePrice';
import { TileName } from '@components/Tiles/components/TileName/TileName';
import { useLocalization } from '@hooks/useLocalization';
import { useSelectItemEvent } from '@modules/ga/hooks/useSelectItemEvent';
import { isEligibleForAddingToShoppingList } from '@utils/isEligibleForAddingToShoppingList';
import { routing } from '@constants/routing';
import { TrackViewItemList } from '@modules/ga/events/ecommerce/view-item-list/TrackViewItemList';
import { usePageListName } from '@modules/ga/hooks/usePageListName';
import { ProductListChannelProvider, useProductListChannel } from '@modules/ga/context/channel';
import { ProductListLocationProvider, useProductListLocation } from '@modules/ga/context/location';
import { useCriteoBeacon } from '@hooks/criteo/useCriteoBeacon';
import { sendBeacon } from '@hooks/criteo/useCriteoBeacon.utils';
import { AddToShoppingListButton } from '@components/AddToShoppingListButton/AddToShoppingListButton';
import { TOnAddToBag } from '@modules/ga/type';
import { useChooseProductForShoppingList } from '@hooks/shoppingLists/useChooseProductForShoppingList';
import { TileStandingOrderButton } from '@components/Tiles/components/TileStandingOrderButton/TileStandingOrderButton';
import { getBreadcrumbs } from '@modules/ga/utils/getBreadcrumbs';
import { CriteoFormatBeacon } from '@modules/criteo';
import { ProductTileV2 } from '@components/Tiles/ProductTileV2/ProductTileV2';
import { GIFT_CARD_SKU_CODE } from '@components/ProductQuantitySelector/constants';
import { AddToCartContextProvider } from '@context/AddToCartContext/AddToCartContext';
import styles from './ProductTile.module.scss';
import { ProductTileQuantitySelector } from './ProductTileQuantitySelector';

export interface ProductTileProps {
  children?: never;
  className?: string;
  product: IProductTile;
  hideGroupScale?: boolean;
  isShowImage?: boolean;
  isReorder?: boolean;
  onAddToBag?: TOnAddToBag;
  onClick?: (productId?: string) => void;
  onClickCriteo?: () => void;
  nonFocusable?: boolean;
  freeItem?: boolean;
  hideShoppingListButton?: boolean;
  productCarouselType?: string;
  criteoFormatBeaconView?: CriteoFormatBeacon;
  clippedCouponId?: string;
  onClipCouponEvent?: (couponId: string) => void;
  onChange?: (product?: IProductTile | AutosuggestProduct) => void;
  skipAddToCart?: boolean;
  isReorderItem?: boolean;
  isV2?: boolean;
}

export const ProductTile = ({
  className,
  product,
  isShowImage = true,
  hideGroupScale = false,
  isReorder,
  onClickCriteo,
  onClick,
  nonFocusable,
  freeItem = false,
  hideShoppingListButton = false,
  productCarouselType,
  criteoFormatBeaconView,
  clippedCouponId,
  onClipCouponEvent,
  onChange,
  skipAddToCart,
  isReorderItem,
  isV2 = false,
}: ProductTileProps) => {
  const router = useRouter();
  const { t } = useLocalization('productTile');
  const { chooseProduct } = useChooseProductForShoppingList();
  const { getListName } = usePageListName();
  const { getLocation } = useProductListLocation();
  const productRef = useRef<HTMLDivElement>(null);
  const isHovered = useHover(productRef);
  const shouldRenderGroupScale = () => !hideGroupScale && product.groupScale?.grpId;
  const shouldRenderCoupon = product.coupon?.couponId;
  const hasCouponOrGroupscale = shouldRenderGroupScale() || shouldRenderCoupon;
  const { trackSelectItem } = useSelectItemEvent();
  const { fireRWProductClickEvent } = useProductListChannel();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const dataVariantProp = product.variantId ? { 'data-variant': product.variantId } : {};
  const listName = getListName(product);
  const { unitSize, unitPrice, price, pricePerScaleUnit, roughPricePerUnit, defaultScaleUnit } =
    product;
  const shouldRenderTileQuantity =
    (unitSize || unitPrice || pricePerScaleUnit || roughPricePerUnit || defaultScaleUnit) &&
    price.currency;
  const [isFocused, setFocus] = useState(false);
  const isGiftCardSku = product?.skuCode === GIFT_CARD_SKU_CODE;

  const { handleClick } = useCriteoBeacon({
    onClickBeacon: product.clickBeacon,
    onLoadBeacon: product.imageBeacon,
    onViewBeacon: product.viewBeacon,
    componentRef: productRef,
    criteoFormatBeaconView,
  });

  const handleProductClick = (trackListName: string) => {
    if (product) {
      trackSelectItem(product, trackListName);
      fireRWProductClickEvent(product);
    }
    onClickCriteo?.();
    onClick?.();
    handleClick?.();
  };

  const getChannel = () => {
    if (product.marketingTags.sponsored) {
      return 'rec_criteo';
    }
  };

  const getProductLocation = () => {
    const pathname = router.asPath;
    if (product.marketingTags.sponsored && pathname.startsWith(routing.allSales)) {
      return 'cat_' + getBreadcrumbs().split('/').join('');
    }

    return getLocation();
  };

  const handleAddToShoppingList = () => {
    chooseProduct({
      product,
      configurationOptions: getConfigurationOptions(product.variations),
      salesUnit: product.salesUnits.find((e) => e.selected)?.alternateSalesUnit,
    });
    if (product?.clickBeacon) {
      sendBeacon(product.clickBeacon);
    }
  };

  const handleBlur = (event: FocusEvent<HTMLDivElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setFocus(false);
    }
  };

  const handleFocus = (event: FocusEvent<HTMLDivElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setFocus(true);
    }
  };

  return (
    <AddToCartContextProvider>
      {isV2 && isShowImage ? (
        <ProductTileV2
          className={className}
          product={product}
          isShowImage={isShowImage}
          hideGroupScale={hideGroupScale}
          isReorder={isReorder}
          onClickCriteo={onClickCriteo}
          onClick={onClick}
          nonFocusable={nonFocusable}
          freeItem={freeItem}
          hideShoppingListButton={hideShoppingListButton}
          productCarouselType={productCarouselType}
          criteoFormatBeaconView={criteoFormatBeaconView}
          clippedCouponId={clippedCouponId}
          onClipCouponEvent={onClipCouponEvent}
          onChange={onChange}
          skipAddToCart={skipAddToCart}
          isReorderItem={isReorderItem}
        />
      ) : (
        <ProductListChannelProvider channel={getChannel()}>
          <ProductListLocationProvider location={getProductLocation()}>
            <TrackViewItemList productRef={productRef} product={product} listName={listName} />
            <div
              className={styles.product_card_wrapper}
              ref={productRef}
              data-ga-tile-list={getListName(product)}
              data-ga-tile-list-type="product-tile"
              data-ga-tile-id={product.productId}
              data-testid={`product-tile-${product.productId}`}
              onBlur={handleBlur}
              onFocus={handleFocus}
            >
              <div
                // product_tile_card class is used by GTM
                className={`product_tile_card ${cx(
                  styles.product_card,
                  className,
                  styles.product_hover,
                )}`}
                {...dataVariantProp}
              >
                <div className={styles.product_card_main_info}>
                  {product.productImage.ref && isShowImage && (
                    <TileImage
                      img={{
                        ref: product.productImage.ref,
                        alt: product.productImage.alt ?? t('ariaLabels.tileImage'),
                      }}
                      productUrl={product.productPageUrl}
                      hasWineData={product?.hasWineData}
                      expressEligible={product.featureTags.expressEligible}
                      peakQuality={product.featureTags.topPick}
                      onProductClick={handleProductClick}
                    />
                  )}
                  <TileLabels
                    marketingTags={product.marketingTags}
                    preparationTime={product.preparationTime}
                  />
                  <TileTagList
                    expressEligible={product.featureTags.expressEligible}
                    peakQuality={product.featureTags.topPick}
                  />
                  <TileName
                    name={product.productName}
                    brand={product.brandName}
                    url={product.productPageUrl}
                    onProductClick={handleProductClick}
                    nonFocusable={nonFocusable}
                  />
                </div>

                {hasCouponOrGroupscale && !nonFocusable && (
                  <div
                    className={styles.product_card_promo_cnt}
                    data-testid={t('ariaLabels.productInfo')}
                    data-product-id={product.productId}
                  >
                    {shouldRenderGroupScale() && (
                      <div
                        className={styles.product_card_promo}
                        aria-label={t('ariaLabels.groupScales')}
                      >
                        <GroupScale
                          {...product.groupScale}
                          grpPrices={product.grpPrices}
                          id={product.productId}
                        />
                      </div>
                    )}

                    {shouldRenderCoupon && (
                      <div
                        className={styles.product_card_coupon}
                        aria-label={t('ariaLabels.groupScales')}
                      >
                        <Coupon
                          productId={product.productId}
                          title={product.coupon.description}
                          id={product.coupon.couponId}
                          isActive={product.coupon.isActive}
                          prompt={product.coupon.detailedDescription}
                          expirationDate={product.coupon.expirationDate}
                          clippedCouponId={clippedCouponId}
                          onClipCouponEvent={onClipCouponEvent}
                          fullWidth
                        />
                      </div>
                    )}
                  </div>
                )}
                <div
                  className={styles.product_card_sub_info}
                  data-testid={t('ariaLabels.productInfo')}
                >
                  <div className={styles.product_card_price_block}>
                    {shouldRenderTileQuantity && (
                      <TileQuantity
                        unitPrice={unitPrice}
                        unitSize={unitSize}
                        currency={price.currency}
                        roughPricePerUnit={roughPricePerUnit}
                        defaultScaleUnit={defaultScaleUnit}
                        pricePerScaleUnit={pricePerScaleUnit}
                      />
                    )}

                    <TilePrice
                      formattedCurrentPrice={product.formattedCurrentPrice}
                      currentPrice={product.price}
                      scaleUnit={product.scaleUnit}
                      prevPrice={product?.wasPrice}
                      minValueToOrder={product.quantity.minQuantity}
                      savingString={product.savingString}
                      discount={product.deal}
                      freeItem={freeItem}
                    />
                  </div>

                  {!hideShoppingListButton && (
                    <AddToShoppingListButton
                      product={product}
                      classNames={styles.product_card_shoppling_list_wrapper}
                      shouldRenderAddToShoppingListButton={isEligibleForAddingToShoppingList(
                        product,
                      )}
                      handleAddToShoppingList={handleAddToShoppingList}
                      nonFocusable={nonFocusable}
                    />
                  )}

                  <div className={styles.product_card_price_selector}>
                    <ProductTileQuantitySelector
                      product={product}
                      productCarouselType={productCarouselType}
                      isReorder={isReorder}
                      nonFocusable={nonFocusable}
                      freeItem={freeItem}
                      onChange={onChange}
                      skipAddToCart={skipAddToCart}
                      isReorderItem={isReorderItem}
                      isV2={isV2}
                    />
                  </div>
                </div>
              </div>
              {isShowImage && !freeItem && !isGiftCardSku && (
                <TileStandingOrderButton product={product} hidden={!(isHovered || isFocused)} />
              )}
            </div>
          </ProductListLocationProvider>
        </ProductListChannelProvider>
      )}
    </AddToCartContextProvider>
  );
};

ProductTile.displayName = 'ProductTile';
