import { fireEvent } from '@modules/ga/emitter';
import { GA_ECOM_EVENT_ACTION } from '@modules/ga/type';
import { GA_EVENT_TYPE } from '@modules/ga/constants';

export const fireSelectDeliveryAddressEvent = () => {
  fireEvent({
    type: GA_EVENT_TYPE,
    message: GA_ECOM_EVENT_ACTION.SELECT_DELIVERY_ADDRESS,
  });
};
