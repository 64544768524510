import { ReactNode, useEffect, useMemo, useState, useCallback } from 'react';
import { getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import { useCacheFieldsInvalidate } from '@hooks/useCacheFieldsInvalidate';
import { CAROUSELS_CACHE_FIELDS, PRODUCT_CACHE_FIELDS } from '@constants/cacheFields';
import { useCurrentDeliveryAddress } from '@hooks/deliveryAddress/useCurrentDeliveryAddress';
import { COOKIES_LIST } from '@commons/cookies';
import { localStorageService, STORAGE_KEYS } from '@utils/storageService';
import { expressContextQuery, getExpressInitialStateFromQuery } from '@modules/express-header';
import { useRouterQuery } from '@hooks/useRouterQuery';
import { useExpressEvent } from '@modules/ga/hooks/useExpressEvent';
import { LIGHT_CART_CACHE_FIELD } from '@graphql/cart/queries/getLightCart';
import { USER_DETAILS_CACHE_FIELD } from '@graphql/common/queries/userDetails';
import { useUserDetails } from '@hooks/account/useUserDetails';
import { ExpressContext, ExpressContextState } from './ExpressContext';

interface ExpressProviderProps {
  children: ReactNode;
}

const GLOBAL_HEADER_CACHE_FIELD = 'globalHeader';

export const CACHE_FIELDS_DEPEND_ON_EXPRESS_STATE = [
  ...PRODUCT_CACHE_FIELDS,
  ...CAROUSELS_CACHE_FIELDS,
  GLOBAL_HEADER_CACHE_FIELD,
  LIGHT_CART_CACHE_FIELD,
  USER_DETAILS_CACHE_FIELD,
];

export const ExpressProvider = ({ children }: ExpressProviderProps) => {
  const router = useRouter();
  const { data: userDetails, loading: loadingUserDetails } = useUserDetails();
  const { removeQueryParams } = useRouterQuery();
  const initialExpressState = getExpressInitialStateFromQuery(router.query);
  const { address, expressEligibleAddress, addressLoading } = useCurrentDeliveryAddress();
  const { clearCacheFields } = useCacheFieldsInvalidate();
  const initiallyEnabled = !isUndefined(initialExpressState)
    ? initialExpressState
    : !!getCookie(COOKIES_LIST.IS_EXPRESS);
  const [enabled, setEnabled] = useState(initiallyEnabled);
  const [preliminaryEnabled, setPreliminaryEnabled] = useState(initiallyEnabled);
  const { expressEnabled } = userDetails;

  useEffect(() => {
    if (router.query.hasOwnProperty(expressContextQuery)) {
      removeQueryParams([expressContextQuery]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCookie(COOKIES_LIST.IS_EXPRESS, preliminaryEnabled);
    setEnabled(preliminaryEnabled);
  }, [preliminaryEnabled]);

  const handleToggle = useCallback(
    (value: boolean, shouldCleanCache = true) => {
      if (value !== preliminaryEnabled) {
        setCookie(COOKIES_LIST.IS_EXPRESS, value);
        if (shouldCleanCache) {
          clearCacheFields(CACHE_FIELDS_DEPEND_ON_EXPRESS_STATE);
        }
        setPreliminaryEnabled(value);
      }
    },
    [clearCacheFields, preliminaryEnabled],
  );

  useEffect(() => {
    if (!loadingUserDetails && !enabled && expressEnabled) {
      handleToggle(true, true);
    }
  }, [loadingUserDetails, enabled, expressEnabled, handleToggle]);

  useEffect(() => {
    if (address.id && !expressEligibleAddress && !addressLoading) {
      setPreliminaryEnabled(false);
    }
  }, [expressEligibleAddress, addressLoading, address.id]);

  useEffect(() => {
    if (!addressLoading && !expressEligibleAddress && enabled) {
      clearCacheFields([GLOBAL_HEADER_CACHE_FIELD]);
    }
  }, [clearCacheFields, expressEligibleAddress, addressLoading, enabled]);

  useExpressEvent(enabled);

  const saveExpressFilterToStorage = useCallback((value: boolean) => {
    localStorageService?.put(STORAGE_KEYS.EXPRESS_FILTER, value);
  }, []);

  const getExpressFilterFromStorage = useCallback(() => {
    const expressFilter = localStorageService?.read(STORAGE_KEYS.EXPRESS_FILTER);
    if (!isUndefined(expressFilter) && !isNull(expressFilter)) {
      handleToggle(expressFilter);
      localStorageService?.remove(STORAGE_KEYS.EXPRESS_FILTER);
    }
  }, [handleToggle]);

  const expressState: ExpressContextState = useMemo(
    () => ({
      enabled,
      toggle: handleToggle,
      saveExpressFilterToStorage,
      getExpressFilterFromStorage,
      eligible: expressEligibleAddress,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enabled, getExpressFilterFromStorage, saveExpressFilterToStorage, expressEligibleAddress],
  );

  return <ExpressContext.Provider value={expressState}>{children}</ExpressContext.Provider>;
};
