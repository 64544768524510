import { useState } from 'react';
import { Grid } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { Link } from '@components/UI/Link/Link';
import { FeaturedCategoryCarousel } from '@components/FeaturedCategoryCarousel/FeaturedCategoryCarousel';
import { ProductCarouselNavigation } from '@components/ProductCarousel/components/ProductCarouselNavigation/ProductCarouselNavigation';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import type { ModuleData } from '@commons/home';
import { useViewAllTracking } from '@hooks/carousel/useViewAllTracking';
import { getCurrentTitle } from '@utils/getCurrentTitle';
import { ProductListTitleProvider } from '@modules/ga/context/title';
import { hasModuleImageHighPriority } from '@helpers/common.helpers';
import { DYNAMIC_FEATURES, isFeatureEnabled } from '@utils/dynamicFeatures';
import { TermsModule } from '../TermsModule/TermsModule';
import { useFeaturedCategory } from './useFeaturedCategory';
import styles from './FeaturedCategoryModule.module.scss';

interface FeaturedCategoryModuleProps {
  module: ModuleData;
  position?: number;
  className?: string;
  classNameForLink?: string;
}

export const FeaturedCategoryModule = ({
  module,
  position,
  className,
  classNameForLink,
}: FeaturedCategoryModuleProps) => {
  const router = useRouter();
  const {
    basicModuleData: { headline, subhead, linkText, linkPath, image },
    featuredProducts,
  } = module;
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const { isMobile, isTablet, isDesktop } = useHeaderContext();
  const smallDevice = isMobile || isTablet;
  const { productsPerSlide, isNotEnoughProductsForModule, isCarouselNavigationVisible } =
    useFeaturedCategory(featuredProducts, !!isMobile);
  const { onClick } = useViewAllTracking(`${linkText} ${headline}`);
  const isV2 = isFeatureEnabled(DYNAMIC_FEATURES.NEW_PRODUCT_TILES);

  if (isNotEnoughProductsForModule) {
    return null;
  }

  const content = (
    <Grid justifyContent="space-between" container>
      <Grid className={styles.headline_carousel_section} xs={12} md={8} item>
        <FeaturedCategoryCarousel
          className={styles.carousel}
          allowTouchMove={!isDesktop}
          onSlideChange={(swiper) => {
            setIsEnd(swiper.isEnd);
            setIsStart(swiper.isBeginning);
          }}
          header={
            <div className={styles.carousel_header}>
              <Grid item xs={12} md={8}>
                <div className={styles.title_block}>
                  <h2 className="sr_only">{headline}</h2>
                  <span aria-hidden="true" className={styles.title}>
                    {headline}
                  </span>
                  <Link
                    legacyBehavior
                    href={linkPath}
                    className={cx(styles.linkText, classNameForLink)}
                    onClick={onClick}
                    ariaLabel={`${linkText} ${headline}`}
                  >
                    {linkText}
                  </Link>
                </div>
                <p className={styles.subhead}>
                  <span dangerouslySetInnerHTML={{ __html: subhead + ' ' }} />
                </p>
                <TermsModule
                  linkText={module.basicModuleData.termsText}
                  modalContent={module.basicModuleData.termsEditorialContent}
                />
              </Grid>
              {image && smallDevice && (
                <div className={styles.hero_image}>
                  <Image sizes="250px" src={image} fill alt="" />
                </div>
              )}
              {isCarouselNavigationVisible && (
                <ProductCarouselNavigation
                  isStart={isStart}
                  isEnd={isEnd}
                  className={styles.carousel_navigation}
                  name={headline}
                />
              )}
            </div>
          }
          slides={productsPerSlide}
          isV2={isV2}
        />
      </Grid>
      {image && (
        <Grid className={styles.hero_image} display={{ xs: 'none', md: 'block' }} md={3} item>
          <Image
            sizes="440px"
            src={image}
            fill
            alt=""
            priority={hasModuleImageHighPriority(position)}
          />
        </Grid>
      )}
    </Grid>
  );

  return (
    <div data-position={position} className={className}>
      <ProductListTitleProvider title={getCurrentTitle(router.pathname, headline)}>
        {content}
      </ProductListTitleProvider>
    </div>
  );
};
