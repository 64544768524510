import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { sendGtmEvent } from '@modules/ga/gtm-event';

export interface ReferralLinkEventOptions {
  referralLink: string;
}

export const referralLinkEventHandler = ({ referralLink }: ReferralLinkEventOptions) => {
  sendGtmEvent(
    GtmCustomEvent({
      /* eslint-disable @typescript-eslint/naming-convention */
      event: 'referral_link',
      event_name: 'raf_referral_link',
      ua_action: 'RAf Referral Link',
      referralLink: referralLink,
    }),
  );
};
