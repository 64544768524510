import { FC } from 'react';
import cx from 'classnames';
import { ErrorBlock } from '@components/ErrorBlock/ErrorBlock';
import styles from './SubmitError.module.scss';

interface SubmitErrorProps {
  message: string | null;
  className?: string;
}

export const SubmitError: FC<SubmitErrorProps> = ({ message, className, children }) => (
  <div className={cx(styles.submit_block, className)} aria-live="polite">
    {message && (
      <div className={styles.submit_error}>
        <ErrorBlock message={message} />
      </div>
    )}
    {children}
  </div>
);
