import { IAction } from '@modules/action';
import { GA_EVENT_TYPE } from '@modules/ga/constants';

export enum GA_CUSTOM_EVENT_ACTION {
  SHOP_BY = 'SHOP_BY',
  RW_EXTERNAL_WIDGET_VIEW_GA_EVENT = 'RW_EXTERNAL_WIDGET_VIEW_GA_EVENT',
  RW_PRODUCT_CLICK = 'RW_PRODUCT_CLICK',
  RW_PRODUCT_ADD_TO_CART = 'RW_PRODUCT_ADD_TO_CART',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  COMMON_CUSTOM_PAGE_VIEW = 'COMMON_CUSTOM_PAGE_VIEW',
  SEARCH_CUSTOM_PAGE_VIEW = 'SEARCH_CUSTOM_PAGE_VIEW',
  CART_CUSTOM_PAGE_VIEW = 'CART_CUSTOM_PAGE_VIEW',
  USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS',
  EXPRESS_ON = 'EXPRESS_ON',
  EXPRESS_OFF = 'EXPRESS_OFF',
  SEARCH = 'SEARCH',
  REFINE_RESULT = 'REFINE_RESULT',
  CHANGE_ADDRESS = 'CHANGE_ADDRESS',
  CATEGORY_DATA_LOAD = 'CATEGORY_DATA_LOAD',
  LOGOUT = 'LOGOUT',
  APP_REDIRECT_CLICK_EVENT = 'APP_REDIRECT_CLICK_EVENT',
  APP_REDIRECT_DISPLAY_EVENT = 'APP_REDIRECT_DISPLAY_EVENT',
  REFERRAL_LINK = 'REFERRAL_LINK',
}

export enum GA_ECOM_EVENT_ACTION {
  VIEW_ITEM = 'VIEW_ITEM',
  SELECT_ITEM = 'SELECT_ITEM',
  VIEW_ITEM_LIST = 'VIEW_ITEM_LIST',
  PURCHASE_EVENT = 'PURCHASE_EVENT',
  ADD_TO_CART = 'ADD_TO_CART',
  REMOVE_FROM_CART = 'REMOVE_FROM_CART',
  VIEW_CART = 'VIEW_CART',
  BEGIN_CHECKOUT = 'BEGIN_CHECKOUT',
  ADD_SHIPPING_INFO = 'ADD_SHIPPING_INFO',
  ADD_PAYMENT_INFO = 'ADD_PAYMENT_INFO',
  SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD',
  SELECT_DELIVERY_ADDRESS = 'SELECT_DELIVERY_ADDRESS',
}

export interface HandlerFunction {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (data: any): void;
}
export type GAActionType = GA_CUSTOM_EVENT_ACTION | GA_ECOM_EVENT_ACTION;

export type GaEventList = Record<GAActionType, HandlerFunction>;

export interface GAAction<Payload> extends IAction {
  type: typeof GA_EVENT_TYPE;
  message: string;
  data: Payload;
}

export type TOnAddToBag = (productId: string) => void;
