/* eslint-disable @typescript-eslint/naming-convention */
import { Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { ApolloError } from '@apollo/client/errors';
import { useRouter } from 'next/router';
import Trans from 'next-translate/Trans';
import { Button } from '@components/UI';
import { useLocalization } from '@hooks/useLocalization';
import { useOrderModalState } from '@graphql/variables/orderModalStateVar';
import { useSetPendingOrders } from '@hooks/cart/useSetShowPendingOrders';
import { Loader } from '@components/Loader/Loader';
import { useSetProductToAddToOrder } from '@hooks/order/useSetProductToAddToOrder';
import { getModifyingOrderLink } from '@utils/getModifyingOrderLink';
import { sendGtmEvent } from '@modules/ga/gtm-event';
import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { ORDER_MODAL_STATE } from '@constants/orderModalState';
import { useModifiedAddToCartLight } from '@hooks/cart/useAddToCartLight';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { CallbackFunction } from '@modules/auth/hooks/useProtectedCallback';
import { getModifiableOrders, useGetPendingOrders } from '@hooks/order/useGetPendingOrders';
import {
  setReorderErrMsgVar,
  setReorderIndexVar,
  setShowPendingOrdersModalVar,
} from '@graphql/variables/showPendingOrdersModalVar';
import { useAddToOrderLightV2 } from '@hooks/order/useAddToOrderLightV2';
import { routing } from '@constants/routing';
import { useDeliveryPassInfo } from '@hooks/deliveryPass/useDeliveryPassInfo';
import { useServiceType } from '@hooks/useServiceType';
import { MODAL_TITLE_ID } from '../Modal';
import { OrderList } from './components/OrderList/OrderList';
import { OrderModificationContainer } from './components/OrderModificationContainer/OrderModificationContainer';
import styles from './OrderModification.module.scss';

interface OrderModificationProps {
  shouldShowPendingOrdersModal: boolean;
}

export const OrderModification = ({
  shouldShowPendingOrdersModal = false,
}: OrderModificationProps) => {
  const { data, loading } = useGetPendingOrders();
  const modifiableOrders = useMemo(() => getModifiableOrders(data), [data]);

  const [addToCart] = useModifiedAddToCartLight();
  const [addToOrder] = useAddToOrderLightV2();
  //need to use useState to show loading state because loading state from mutation turns to "true" value with some delay
  const [addToOrderLoading, setAddToOrderLoading] = useState(false);
  const [setIsShown] = useSetPendingOrders();
  const { getProductToAddToOrder } = useSetProductToAddToOrder();
  const { setOrderModalState } = useOrderModalState();
  const { data: deliveryPassInfo } = useDeliveryPassInfo();

  const [selectedOrderId, setSelectedOrderId] = useState(modifiableOrders[0]?.orderId ?? '');
  const { t } = useLocalization('common');
  const router = useRouter();
  const { setProtectedCallback } = useAuthContext();
  const { isCorporateServiceType } = useServiceType();

  useEffect(() => {
    if (modifiableOrders) {
      setSelectedOrderId(modifiableOrders[0]?.orderId ?? '');
    }
  }, [modifiableOrders]);

  const handleOnChange = (orderId: string) => {
    setSelectedOrderId(orderId);
  };

  const finishAddToOrder = () => {
    setAddToOrderLoading(false);
    setOrderModalState(ORDER_MODAL_STATE.MODIFYING);
    router.replace(getModifyingOrderLink(selectedOrderId));
    setShowPendingOrdersModalVar(false);
    setIsShown({
      variables: {
        isShown: true,
      },
    });
  };

  const handleAddToOrder = () => {
    if (addToOrderLoading) {
      return;
    }

    setAddToOrderLoading(true);
    const protectedAddToOrder: CallbackFunction = (failure) => {
      return addToOrder({
        variables: getProductToAddToOrder(selectedOrderId),
        onCompleted: () => {
          finishAddToOrder();
        },
        onError: (error) => {
          const errMsgsArray = error?.graphQLErrors?.[0]?.extensions?.messageArgs;
          setAddToOrderLoading(false);
          failure(error);
          if (!!errMsgsArray) {
            setReorderIndexVar();
            setReorderErrMsgVar(errMsgsArray as [string]);
            finishAddToOrder();
          }
        },
      });
    };
    setProtectedCallback(protectedAddToOrder);
    sendGtmEvent(
      GtmCustomEvent({
        event: 'modify-click',
        event_name: 'enter_modify_mode',
        ua_category: 'modify',
        ua_action: 'enter modify mode',
        ua_label: router.pathname,
      }),
    );
  };
  const handleAddToCart = () => {
    sendGtmEvent(
      GtmCustomEvent({
        event: 'modify-click',
        event_name: 'modify_atc_create_new_order',
        ua_category: 'modify',
        ua_action: 'create new order',
        ua_label: 'atc create new',
      }),
    );
    addToCart({
      onError: (err: ApolloError) => {
        const errMsgsArray = err?.graphQLErrors?.[0]?.extensions?.messageArgs;
        if (!!errMsgsArray) {
          setReorderErrMsgVar(errMsgsArray as [string]);
          setShowPendingOrdersModalVar(false);
        }
      },
    });
  };

  if (!shouldShowPendingOrdersModal) return null;

  return (
    <OrderModificationContainer
      open={shouldShowPendingOrdersModal}
      onClose={() => addToCart()}
      titleId={MODAL_TITLE_ID.ORDER_MODIFICATION_CONTAINER_MODAL}
    >
      <div className={styles.container}>
        <h2 className={styles.title} id={MODAL_TITLE_ID.ORDER_MODIFICATION_CONTAINER_MODAL}>
          {t('modifyingModal.title')}
        </h2>

        {!deliveryPassInfo.deliveryPassApplied && !isCorporateServiceType && (
          <Typography className={styles.additional_info}>
            <Trans
              i18nKey="common:modifyingModal.additionalInfo"
              components={{
                button: (
                  <Button
                    variant="underline"
                    onClick={() => router.push(routing.deliveryPass)}
                    className={styles.link}
                  />
                ),
              }}
            ></Trans>
          </Typography>
        )}

        {modifiableOrders.length > 1 && (
          <div className={styles.orders_container}>
            {loading && <Loader />}
            <OrderList
              orders={modifiableOrders.map(
                ({ orderId, requestedDate, deliveryStart, deliveryEnd }) => ({
                  orderId,
                  deliveryStart,
                  deliveryEnd,
                  requestedDate,
                }),
              )}
              onChange={handleOnChange}
            />
          </div>
        )}

        <Button
          className={styles.add_to_order_btn}
          size="large"
          onClick={handleAddToOrder}
          loading={addToOrderLoading}
          fullWidth
        >
          {t('modifyingModal.addToOrder')}
        </Button>

        <Button size="large" variant="outlined" onClick={handleAddToCart} fullWidth>
          {t('modifyingModal.startNewBag')}
        </Button>
      </div>
    </OrderModificationContainer>
  );
};
