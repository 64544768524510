import { Typography } from '@mui/material';
import styles from './ReferAFriendFooter.module.scss';

interface ReferAFriendFooterProps {
  rafPromoData?: {
    legalInfo: string;
    rafFooterMedia?: string;
  };
  inviteSuccessCallback?: () => void;
}

export const ReferAFriendFooter = ({ rafPromoData }: ReferAFriendFooterProps) => {
  return (
    <>
      {rafPromoData?.rafFooterMedia && (
        <div
          className={styles.footer}
          dangerouslySetInnerHTML={{ __html: rafPromoData.rafFooterMedia }}
        />
      )}
      {rafPromoData?.legalInfo && (
        <div className={styles.terms_and_conditions}>
          <Typography className={styles.terms_and_conditions_title} component="p">
            {rafPromoData.legalInfo}
          </Typography>
        </div>
      )}
    </>
  );
};
