import React, { useEffect, useState } from 'react';
import { SwipeableDrawer } from '@mui/material';
import { LastDeliveredOrderInfo } from '@commons/order';
import { ModalWindow } from '@components/UI/ModalWindow/ModalWindow';
import { useModalState } from '@graphql/variables/orderDeliveryVar';
import { useAuthModalContext } from '@context/AuthModalContext';
import { ContactUsModalProvider } from '@context/contactUsModalContext';
import { localStorageService, STORAGE_KEYS } from '@utils/storageService';
import { useLazyLastDeliveredOrders } from '@hooks/order/useLazyLastDeliveredOrders';
import { useOrderDeliveryModalContext } from '@context/OrderDeliveryModalContext/OrderDeliveryModalContext';
import { OrderDeliveryModalContent } from './components/Content/Content';
import styles from './OrderDeliveryModal.module.scss';

export const OrderDeliveryModal = () => {
  const { on } = useAuthModalContext();
  const { emit } = useOrderDeliveryModalContext();
  const [fetchLastDeliveredOrders, query] = useLazyLastDeliveredOrders();
  const { modalStateVar, isModalOpen } = useModalState();

  // TODO: figure out where to store orders that were already shown in modal
  const deliveredOrdersFromStorage =
    localStorageService?.read(STORAGE_KEYS.DELIVERED_ORDERS_INFO) ?? [];
  const [deliveredOrders, setDeliveredOrders] = useState<LastDeliveredOrderInfo[]>([]);

  useEffect(() =>
    on?.((action) => {
      if (action.type === 'signIn') {
        setTimeout(async () => {
          await fetchLastDeliveredOrders();
        }, 2700);
      }
    }),
  );

  useEffect(() => {
    if (query.data.lastDeliveredOrders.length > 0 && !query.loading) {
      const fetchedLastDeliveredOrders = query.data.lastDeliveredOrders;
      let uniqueDeliveredOrders = fetchedLastDeliveredOrders;

      if (deliveredOrdersFromStorage?.length > 0) {
        const orderIdSet = new Set(deliveredOrdersFromStorage.map(({ orderId }) => orderId));
        uniqueDeliveredOrders = fetchedLastDeliveredOrders.filter(
          ({ orderId: fetchedId }) => !orderIdSet.has(fetchedId),
        );
      }

      localStorageService?.put(STORAGE_KEYS.DELIVERED_ORDERS_INFO, fetchedLastDeliveredOrders);

      if (uniqueDeliveredOrders.length > 0) {
        setDeliveredOrders(uniqueDeliveredOrders);
        modalStateVar(true);
        emit({ type: 'openOrderDelivery' });
      } else {
        emit({ type: 'loadedEmptyOrders' });
      }
    }

    if (query.data.lastDeliveredOrders.length === 0 && !query.loading) {
      emit({ type: 'loadedEmptyOrders' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.loading, modalStateVar]);

  const handleClose = () => {
    modalStateVar(false);
    emit({ type: 'closeOrderDelivery' });
  };

  const handleContactUsModal = () => {
    emit({ type: 'triggerContactUsModal' });
    // fix screen blinking
    setTimeout(() => modalStateVar(false), 500);
  };

  return (
    <ContactUsModalProvider>
      <ModalWindow
        onClose={handleClose}
        open={isModalOpen}
        className={styles.modal}
        wrapperClassName={styles.wrapper}
        modalClassName={styles.desktopModal}
      >
        <OrderDeliveryModalContent
          onClose={handleClose}
          onKeyDown={handleClose}
          onOpenContactUsModal={handleContactUsModal}
          deliveredOrders={deliveredOrders}
        />
      </ModalWindow>
      <SwipeableDrawer
        className={styles.mobileModal}
        anchor="bottom"
        open={isModalOpen}
        onClose={handleClose}
        // it is intended that onOpen won't be called ever
        onOpen={() => void 0}
        swipeAreaWidth={59}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: false,
        }}
        SwipeAreaProps={{ className: styles.swipeArea }}
      >
        <OrderDeliveryModalContent
          onClose={handleClose}
          onKeyDown={handleClose}
          onOpenContactUsModal={handleContactUsModal}
          deliveredOrders={deliveredOrders}
        />
      </SwipeableDrawer>
    </ContactUsModalProvider>
  );
};
