import { gql } from '@apollo/client';

export const INVITE_FRIEND = gql`
  mutation SendReferralInvites($emails: String!) {
    sendReferralInvites(emails: $emails) {
      success
      message
    }
  }
`;

export const APPLY_RAF_TO_USER = gql`
  mutation ApplyRafToUser($userCode: String!, $promoCode: String!) {
    applyRafToUser(referralInput: { referralUserCode: $userCode, referralPromoCode: $promoCode })
  }
`;
