import { gql } from '@apollo/client';

export const GET_REFERRAL_PROMOTION_DATA = gql`
  query ReferralPromotionData {
    referralPromotionData {
      shareHeader
      shareText
      giveHeader
      giveText
      getHeader
      getText
      referralPageText
      referralProgramId
      legalInfo
      promotionActivationThreshold
      refererCredit
      promotionDiscount
      rafHeaderMedia
      rafFooterMedia
    }
  }
`;
