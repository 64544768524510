import { ReactNode, createContext, useContext, useMemo } from 'react';
import { useScrolling } from '@hooks/index';

interface ScrollingContextInterface {
  isScrolling: boolean;
}

interface ScrollingContextProviderProps {
  children: ReactNode;
}

const ScrollingContext = createContext<ScrollingContextInterface>({
  isScrolling: false,
});

export const useScrollingContext = () => useContext(ScrollingContext);

export const ScrollingContextProvider = ({ children }: ScrollingContextProviderProps) => {
  const isScrolling = useScrolling();

  const value = useMemo(
    () => ({
      isScrolling,
    }),
    [isScrolling],
  );

  return <ScrollingContext.Provider value={value}>{children}</ScrollingContext.Provider>;
};
