import { QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import { accountAdapter } from '@adapters/accountAdapter';
import {
  Query,
  UpdateAccountWorkPhoneMutation,
  UpdateAccountWorkPhoneMutationVariables,
} from '@api';
import {
  AccountMobileNotificationsInput,
  AccountPasswordInput,
  ActionError,
  ALERT_TYPES,
} from '@commons/account';
import { Customer } from '@commons/customer';
import { useAccountAlertContext } from '@context/AccountAlertContext';
import { ACCOUNT_PREFERENCES } from '@graphql/account';
import { UNLINK_SOCIAL_ACCOUNT } from '@graphql/account/mutations/unlinkSocialAccount';
import { UPDATE_ACCOUNT_EMAIL } from '@graphql/account/mutations/updateAccountEmail';
import { UPDATE_ACCOUNT_EMAIL_PREFERENCES } from '@graphql/account/mutations/updateAccountEmailPreferences';
import { UPDATE_ACCOUNT_MOBILE_NOTIFICATIONS } from '@graphql/account/mutations/updateAccountMobileNotifications';
import { UPDATE_ACCOUNT_NAME } from '@graphql/account/mutations/updateAccountName';
import { UPDATE_ACCOUNT_PASSWORD } from '@graphql/account/mutations/updateAccountPassword';
import { useLocalization } from '@hooks/useLocalization';
import { responseErrorHandler } from '@utils/responseErrorHandler';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { getUserFromAccountPreferences } from '@modules/auth/utils/getUserFromAccountPreferences';
import { AuthUser } from '@modules/auth/types';
import { UPDATE_ACCOUNT_WORK_PHONE } from '@graphql/account/mutations/updateAccountWorkPhone';
import { updateAccountPreferencesCache } from './account/updateAccountPreferencesCache';
import { updateAccountPreferencesNotificationsCache } from './account/updateAccountPreferencesNotificationsCache';

interface UpdateWorkPhoneOptions {
  workPhoneNumber: string;
  workPhoneExtension: string;
  showMessage?: boolean;
}

export const useAccountPreferences = (options?: QueryHookOptions) => {
  const { t } = useLocalization('account');
  const {
    isKnownUser: isLoggedIn,
    updateAuthUser: updateCustomerInfo,
    isRecognizedUser,
  } = useAuthContext();
  const { data: accountPreferencesData, loading } = useQuery<Query>(ACCOUNT_PREFERENCES, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    skip: !isLoggedIn || isRecognizedUser,
    onCompleted: (data) => {
      updateCustomer(getUserFromAccountPreferences(data));
    },
    ...options,
  });
  const { dispatchAlert } = useAccountAlertContext();
  const [updateAccountEmailMutation] = useMutation(UPDATE_ACCOUNT_EMAIL);
  const [updateAccountEmailPreferencesMutation] = useMutation(UPDATE_ACCOUNT_EMAIL_PREFERENCES);
  const [updateAccountPasswordMutation] = useMutation(UPDATE_ACCOUNT_PASSWORD);
  const [updateAccountNameMutation] = useMutation(UPDATE_ACCOUNT_NAME);
  const [unlinkSocialAccountMutation] = useMutation(UNLINK_SOCIAL_ACCOUNT);
  const [updateAccountMobileNotificationsMutation] = useMutation(
    UPDATE_ACCOUNT_MOBILE_NOTIFICATIONS,
  );

  const [updateWorkPhoneMutation] = useMutation<
    UpdateAccountWorkPhoneMutation,
    UpdateAccountWorkPhoneMutationVariables
  >(UPDATE_ACCOUNT_WORK_PHONE);

  const { getAccountPreferences } = accountAdapter();
  const accountPreferences = getAccountPreferences(accountPreferencesData?.accountPreferences);

  let isEmailUpdateSuccess = true;

  const successHandler = (message: string, showMessage = true) => {
    if (showMessage)
      dispatchAlert(ALERT_TYPES.SUCCESS, t('preferences.alerts.successMessage', { message }));
  };

  const updateCustomer = (customer?: Partial<Customer>) => {
    if (customer) {
      updateCustomerInfo(customer as AuthUser);
    }
  };

  const getErrorMessage = (errorArray: ActionError[]): string => {
    return errorArray.map((error: ActionError) => error.description)[0];
  };

  const updateAccountEmail = (
    newEmail: string,
    showMessage = true,
    successCallback?: () => void,
    failureCallback?: (err: string) => void,
  ) => {
    updateAccountEmailMutation({
      variables: { newEmail },
      onCompleted: (data) => {
        if (data?.updateAccountEmail.success) {
          isEmailUpdateSuccess = true;
          updateCustomer({ email: newEmail });
          successHandler(t('preferences.alerts.message.email'), showMessage);
          successCallback?.();
        } else {
          isEmailUpdateSuccess = false;
          const errorMessage = getErrorMessage(data?.updateAccountEmail?.errors);
          responseErrorHandler(`${errorMessage}`, dispatchAlert);
          failureCallback?.(errorMessage);
        }
      },
      refetchQueries: [ACCOUNT_PREFERENCES],
      onError: (error) => responseErrorHandler(error.message, dispatchAlert),
    });
  };

  const updateAccountEmailPreferences = (sendNewsLetter: boolean, showMessage = true) => {
    updateAccountEmailPreferencesMutation({
      variables: { sendNewsLetter },
      onCompleted: () => {
        updateCustomer({
          notifications: {
            sendNewsLetter,
            orderNotices: accountPreferences.notifications?.orderNotices || false,
            offers: accountPreferences.notifications?.offers || false,
          },
        });

        if (isEmailUpdateSuccess) {
          successHandler(t('preferences.alerts.message.email'), showMessage);
        }
      },
      update(cache) {
        updateAccountPreferencesNotificationsCache(cache, { sendNewsLetter });
      },
      onError: (error) => responseErrorHandler(error.message, dispatchAlert),
    });
  };

  const updateAccountPassword = (input: AccountPasswordInput, showMessage = true) =>
    updateAccountPasswordMutation({
      variables: { input },
      onCompleted: (data) => {
        data?.updateAccountPassword?.success
          ? successHandler(t('preferences.alerts.message.password'), showMessage)
          : responseErrorHandler(
              getErrorMessage(data?.updateAccountPassword?.errors),
              dispatchAlert,
            );
      },
      onError: (error) => responseErrorHandler(error.message, dispatchAlert),
    });

  const updateAccountName = (firstName: string, lastName: string, showMessage = true) => {
    updateAccountNameMutation({
      variables: { firstName, lastName },
      onCompleted: (data) => {
        if (data?.updateAccountName?.success) {
          updateCustomer({ firstName, lastName });
          successHandler(t('preferences.alerts.message.contact'), showMessage);
        } else {
          const errorMessage = getErrorMessage(data?.updateAccountName?.errors);
          responseErrorHandler(errorMessage, dispatchAlert);
        }
      },
      onError: (error) => {
        responseErrorHandler(error.message, dispatchAlert);
      },
    });
  };

  const updateAccountWorkPhone = ({
    workPhoneNumber,
    workPhoneExtension,
    showMessage = true,
  }: UpdateWorkPhoneOptions) => {
    return updateWorkPhoneMutation({
      variables: {
        input: {
          phoneNumber: workPhoneNumber,
          extension: workPhoneExtension,
        },
      },
      onCompleted: (data) => {
        if (data?.updateAccountWorkPhone?.success) {
          successHandler(t('preferences.alerts.message.workPhone'), showMessage);
        } else {
          const errorMessage = getErrorMessage(
            data?.updateAccountWorkPhone?.errors as ActionError[],
          );
          responseErrorHandler(errorMessage, dispatchAlert);
        }
      },
      update(cache, { data }) {
        if (!data?.updateAccountWorkPhone.success) return;
        const newWorkPhone = {
          workPhoneExtension,
          workPhoneNumber,
        };
        updateAccountPreferencesCache(cache, newWorkPhone);
        updateCustomerInfo(newWorkPhone);
      },
    });
  };

  const updateAccountMobileNotifications = (
    input: AccountMobileNotificationsInput,
    showMessage = true,
  ) =>
    updateAccountMobileNotificationsMutation({
      variables: { input },
      onCompleted: (data) => {
        if (data?.updateAccountMobileNotifications?.success) {
          updateCustomer({
            phoneNumber: input.mobileNumber,
            notifications: {
              ...input,
              sendNewsLetter: accountPreferences.notifications?.sendNewsLetter || false,
            },
          });
          successHandler(t('preferences.alerts.message.phone'), showMessage);
        } else {
          const errorMessage = getErrorMessage(data?.updateAccountMobileNotifications?.errors);
          responseErrorHandler(errorMessage, dispatchAlert);
        }
      },
      onError: (error) => responseErrorHandler(error.message, dispatchAlert),
    });

  const unlinkSocialAccount = (
    email: string | null,
    socialNetworkProvider: string | null,
    showMessage = true,
  ) => {
    unlinkSocialAccountMutation({
      variables: {
        socialNetworkProvider,
        email,
      },
      onCompleted: () => {
        successHandler(t('preferences.alerts.message.unlink'), showMessage);
      },
      onError: (error) => responseErrorHandler(error.message, dispatchAlert),
      refetchQueries: [ACCOUNT_PREFERENCES],
    });
  };

  return {
    accountPreferences,
    accountPreferencesLoading: loading,
    updateAccountEmail,
    updateAccountEmailPreferences,
    updateAccountPassword,
    updateAccountName,
    updateAccountMobileNotifications,
    unlinkSocialAccount,
    updateAccountWorkPhone,
  };
};
