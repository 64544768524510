/* eslint-disable @typescript-eslint/naming-convention */
import { IGaCustomer } from '@modules/ga/eventBodyGetters/utils/ga-customer';
import { GtmEvent, PGtmEvent, IGtmEvent } from '../gtm-event';

export interface PGtmCustomEvent extends PGtmEvent {
  event?: string;
  event_name?: string;
  ua_category?: string;
  ua_action?: string;
  ua_label?: string;
  id?: string;
  deliverypass_type?: string;
  pageViewPixelEventId?: string;
  signupPixelEventId?: string;
  pixelEventId?: string;
  customer?: IGaCustomer;
  variantId?: string;
  breadcrumb?: string;
  redirect?: string;
  app_redirect_visible?: string;
  totalProductCount?: number;
  paymentType?: string;
  referralLink?: string;
}

export interface IGtmCustomEvent extends IGtmEvent {
  event: string;
  event_name?: string;
  ua_category?: string;
  ua_action?: string;
  ua_label?: string;
  id?: string;
  deliverypass_type?: string;
  pageViewPixelEventId?: string;
  signupPixelEventId?: string;
  pixelEventId?: string;
  customer?: IGaCustomer;
  variantId?: string;
  breadcrumb?: string;
  redirect?: string;
  app_redirect_visible?: string;
  totalProductCount?: number;
  paymentType?: string;
  referralLink?: string;
}

export function GtmCustomEvent(p: PGtmCustomEvent): IGtmCustomEvent {
  return {
    ...GtmEvent(p),
    event: p.event ?? '',
    ua_category: p.ua_category,
    ua_action: p.ua_action,
    ua_label: p.ua_label,
    id: p.id,
    ...(p.redirect && { redirect: p.redirect }),
    ...(p.app_redirect_visible && { app_redirect_visible: p.app_redirect_visible }),
    ...(p.deliverypass_type && { deliverypass_type: p.deliverypass_type }),
    ...(p.event_name && { event_name: p.event_name }),
    ...(p.pageViewPixelEventId && { pageViewPixelEventId: p.pageViewPixelEventId }),
    ...(p.signupPixelEventId && { signupPixelEventId: p.signupPixelEventId }),
    ...(p.pixelEventId && { pixelEventId: p.pixelEventId }),
    ...(p.customer && { customer: p.customer }),
    ...(p.variantId && { 'data-variant': p.variantId }),
    ...(p.breadcrumb && { breadcrumb: p.breadcrumb }),
    ...(p.totalProductCount && { totalProductCount: p.totalProductCount }),
    ...(p.paymentType && { paymentType: p.paymentType }),
    ...(p.referralLink && { referralLink: p.referralLink }),
  };
}
