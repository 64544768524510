import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { useMutation } from '@apollo/client';
import { useUserDetails } from '@hooks/account/useUserDetails';
import { useRefererInfo } from '@hooks/referAFriend/useRefererInfo';
import { useValidateReferer } from '@hooks/referAFriend/useValidateReferer';
import { useLocalization } from '@hooks/index';
import { useNavigationData } from '@hooks/header/useNavigationData';
import { useReferralPromotionData } from '@hooks/referAFriend/useReferralPromotionData';
import { APPLY_RAF_TO_USER } from '@graphql/account/mutations/referAFriend';
import { useAuthContext } from '@modules/auth/context';
import { BottomLine } from '../BottomLine/BottomLine';
import { TopLine } from '../TopLine/TopLine';
import styles from './NavigationData.module.scss';

interface NavigationDataProps {
  bottomLineHidden: boolean;
}

export const NavigationData = ({ bottomLineHidden }: NavigationDataProps) => {
  const { t } = useLocalization('header');
  const navigationItems = useNavigationData();
  const { state: userState, isKnownUser } = useAuthContext();
  const { refererInfo, setRefererInfo, removeRefererInfo } = useRefererInfo();
  const [hasInitReferInfo, setHasInitReferInfo] = useState(false);
  const bottomLineClasses = cx(styles.wrapper_bottom_line, {
    [styles.hidden]: bottomLineHidden,
  });
  const { data: userDetails, loading: loadingUserDetails } = useUserDetails();
  const referralFlag = !loadingUserDetails && userDetails?.displayRafBanner;
  const [applyRafToUser] = useMutation(APPLY_RAF_TO_USER);

  const saveRefererInfoFromURL = () => {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      const refererName = urlParams.get('referer');
      const refererInvite = urlParams.get('invite');

      if (refererName && refererInvite) {
        return { refererName, refererInvite };
      }
    }
  };

  const urlRefer = saveRefererInfoFromURL();
  const referInfo = urlRefer ?? refererInfo;

  const {
    data: validatedData,
    refetch: fetchRefererInfo,
    loading,
  } = useValidateReferer(referInfo.refererName, referInfo.refererInvite);

  const { data: rafPromoData } = useReferralPromotionData();

  const validatedDataRef = useRef(null);

  const handleRaf = (isPromoValid: boolean) => {
    if (isPromoValid) {
      if (!isKnownUser) {
        setRefererInfo(referInfo);
        validatedDataRef.current = validatedData;
      } else if (referInfo.refererName && referInfo.refererInvite) {
        applyRafToUser({
          variables: {
            userCode: referInfo.refererName,
            promoCode: referInfo.refererInvite,
          },
          onCompleted: (data) => {
            if (data?.applyRafToUser) {
              validatedDataRef.current = validatedData;
            } else {
              removeRefererInfo();
            }
          },
        });
      }
    } else {
      removeRefererInfo();
    }
  };

  useEffect(() => {
    if (
      !loading &&
      ((referInfo.refererInvite && referInfo.refererName) || referralFlag) &&
      !validatedDataRef.current &&
      !hasInitReferInfo &&
      !!userState?.userStatus
    ) {
      setHasInitReferInfo(true);
      fetchRefererInfo().then((res) => {
        handleRaf(res?.data?.validateReferralPromotion?.valid);

        if (typeof window !== 'undefined' && urlRefer) {
          const url = new URL(window.location.href);
          url.searchParams.delete('referer');
          url.searchParams.delete('invite');
          window.history.replaceState({}, document.title, url.toString());
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchRefererInfo,
    referInfo,
    loading,
    hasInitReferInfo,
    removeRefererInfo,
    setRefererInfo,
    referralFlag,
    urlRefer,
    userState,
    isKnownUser,
  ]);

  const shouldShowReferralText =
    (validatedData?.validateReferralPromotion.valid &&
      rafPromoData?.referralPromotionData?.promotionActivationThreshold) ||
    referralFlag;

  return (
    navigationItems && (
      <>
        {shouldShowReferralText && (
          <div className={styles.refer_text}>
            {t('referAFriend', {
              name: validatedData?.validateReferralPromotion.refererName,
              threshold: rafPromoData?.referralPromotionData?.promotionActivationThreshold,
              discount: rafPromoData?.referralPromotionData.promotionDiscount,
            })}
          </div>
        )}

        <TopLine navigationData={navigationItems} />
        <BottomLine className={bottomLineClasses} navigationData={navigationItems} />
      </>
    )
  );
};
