import { GtmCustomEvent } from '@modules/ga/eventBodyGetters/gtm-custom-event';
import { sendGtmEvent } from '@modules/ga/gtm-event';

export interface AppRedirectClickEventOptions {
  paymentType: string;
}

export const selectPaymentEventHandler = ({ paymentType }: AppRedirectClickEventOptions) => {
  sendGtmEvent(
    GtmCustomEvent({
      /* eslint-disable @typescript-eslint/naming-convention */
      event: 'payment_method_selected',
      event_name: 'payment_method_selected_click',
      ua_action: 'Payment Method Selected',
      paymentType: paymentType,
    }),
  );
};
