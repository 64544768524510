import { Typography } from '@mui/material';
import { useEffect } from 'react';
import cx from 'classnames';
import { PriceIndicators as PriceIndicatorsType } from '@commons/cart';
import { PriceIndicators } from '@components/PriceIndicators/PriceIndicators';
import { Price as IPrice } from '@commons/price';
import { useLocalization } from '@hooks/useLocalization';
import { useSideBagDrawerOpenState } from '@graphql/variables/sideBagDrawerOpenVar';
import styles from './Price.module.scss';

interface PriceProps {
  currentPrice: IPrice;
  previousPrice: IPrice;
  priceIndicators: PriceIndicatorsType;
  setIsCartTileUpdating?: (value: boolean) => void;
}

export const Price = ({
  currentPrice,
  previousPrice,
  priceIndicators,
  setIsCartTileUpdating,
}: PriceProps) => {
  const { t } = useLocalization('product');
  const shouldRenderPreviousPrice =
    currentPrice.value !== 0 &&
    currentPrice.formattedPrice !== previousPrice.formattedPrice &&
    previousPrice.value > currentPrice.value;
  const { isSideBagDrawerOpen } = useSideBagDrawerOpenState();

  useEffect(() => {
    setIsCartTileUpdating?.(false);
  });

  return (
    <div
      className={cx(styles.price_wrapper, {
        [styles.drawer_opened]: isSideBagDrawerOpen,
      })}
      aria-live="polite"
      aria-atomic="true"
    >
      {currentPrice.value ? (
        <Typography
          component="span"
          variant="body"
          className={cx({
            [styles.price]: !shouldRenderPreviousPrice,
            [styles.current_price]: shouldRenderPreviousPrice,
          })}
          data-testid="price"
        >
          {shouldRenderPreviousPrice && <span className="sr_only">{t('currentPrice')}</span>}
          {currentPrice.formattedPrice}
          <PriceIndicators values={priceIndicators} />
        </Typography>
      ) : (
        <Typography variant="body" className={styles.free}>
          {t('previewBlock.productPrice.free')}
        </Typography>
      )}
      {shouldRenderPreviousPrice && (
        <Typography
          component="span"
          variant="body"
          className={styles.previous_price}
          data-testid="prev-price"
        >
          <span className="sr_only">{t('earlierPrice')}</span>
          {previousPrice.formattedPrice}
          <PriceIndicators values={priceIndicators} />
        </Typography>
      )}
    </div>
  );
};
