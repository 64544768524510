import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import router from 'next/router';
import cx from 'classnames';
import { useLocalization } from '@hooks/useLocalization';
import { INVITE_FRIEND } from '@graphql/account/mutations/referAFriend';
import { Button } from '@components/UI/Button/Button';
import { InputButton } from '@components/UI/Inputs/InputButton/InputButton';
import { ShareWidget } from '@components/ShareWidget/ShareWidget';
import { useAuthContext } from '@modules/auth/context/AuthContext';
import { useAuthModalContext } from '@context/AuthModalContext';
import { AUTH_MODAL_TYPES } from '@commons/auth';
import { EMAIL_REGEX } from '@utils/validation';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { MobileFormWrapper } from '@components/ReferAFriendHeader/components/MobileFormWrapper';
import breakpoints from '@styles/breakpoints.module.scss';
import styles from './ReferAFriendHeader.module.scss';

interface ReferAFriendHeaderProps {
  referAFriendData?: {
    referAFriend: {
      enabled: boolean;
      referralUrl: string;
      balance: number;
    };
  };
  rafPromoData?: {
    promotionDiscount?: number;
    rafHeaderMedia?: string;
  };
  inviteSuccessCallback?: () => void;
}

export const ReferAFriendHeader = ({
  referAFriendData,
  rafPromoData,
  inviteSuccessCallback,
}: ReferAFriendHeaderProps) => {
  const { t } = useLocalization('referAFriend');
  const { toggleAuthModal } = useAuthModalContext();
  const { isRecognizedUser } = useAuthContext();
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [inviteFriend, { data, loading }] = useMutation(INVITE_FRIEND);
  const { windowSize } = useHeaderContext();
  const isMobile = Number(windowSize?.width) <= Number(breakpoints.smMobile);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputError(false);
    setErrorText('');
    setInputValue(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateField()) {
      inviteFriend({
        variables: { emails: inputValue },
      });
      if (data?.sendReferralInvites?.success) {
        setInputValue('');
      }
    }
  };

  useEffect(() => {
    if (data?.sendReferralInvites?.success) {
      inviteSuccessCallback?.();
      setInputValue('');
    }
  }, [data?.sendReferralInvites?.success]);

  const validateField = () => {
    const allSpacesRemoved = inputValue.replaceAll(' ', '');
    const emailAddresses = allSpacesRemoved.split(',');
    let errorString = '' + t('button.errorMessage');
    let hasError = false;
    emailAddresses.forEach((email) => {
      if (!EMAIL_REGEX.test(email)) {
        const separator = !hasError ? ': ' : ', ';
        errorString += separator + email;
        hasError = true;
      }
    });
    setErrorText(errorString);
    if (hasError) {
      setInputError(true);
    }
    return hasError;
  };

  const inputButtonErrorText = inputError ? errorText : undefined;

  return (
    <div className={styles.content}>
      {rafPromoData?.rafHeaderMedia && (
        <div dangerouslySetInnerHTML={{ __html: rafPromoData.rafHeaderMedia }} />
      )}

      <div role="status">
        {data?.sendReferralInvites && (
          <p
            className={cx(
              { [styles.success]: data?.sendReferralInvites?.success },
              { [styles.error]: !data?.sendReferralInvites?.success },
            )}
          >
            {data.sendReferralInvites.message}
          </p>
        )}
      </div>
      {referAFriendData?.referAFriend && !referAFriendData?.referAFriend?.enabled && (
        <Button className={styles.start_shopping} onClick={() => router.push('/')}>
          {t('button.startShopping')}
        </Button>
      )}
      {referAFriendData && referAFriendData?.referAFriend?.enabled && (
        <>
          <form onSubmit={handleSubmit} noValidate>
            {isMobile ? (
              <MobileFormWrapper
                value={inputValue}
                onChange={handleChange}
                onBlur={validateField}
                onSubmit={handleSubmit}
                loading={loading}
                error={inputError}
                errorText={errorText}
                label={t('button.label')}
                helperText={t('button.helperText')}
                buttonLabel={t('button.sendInvitation')}
              />
            ) : (
              <InputButton
                value={inputValue}
                buttonName={t('button.name')}
                type="string"
                id="refer-a-friend-input"
                helperTextId="helper-text-refer-a-friend"
                helperText={t('button.helperText')}
                fullWidth
                onChange={handleChange}
                onClick={handleSubmit}
                label={t('button.label')}
                error={inputError}
                errorText={inputButtonErrorText}
                onBlur={validateField}
                typeof="email"
                required
              />
            )}
          </form>

          <ShareWidget
            title={t('share.title', { promoAmount: rafPromoData?.promotionDiscount })}
            url={referAFriendData.referAFriend.referralUrl}
            label={t('share.label')}
          >
            {t('share.button')}
          </ShareWidget>
        </>
      )}

      {!referAFriendData?.referAFriend && (
        <>
          {isRecognizedUser ? (
            <Button
              className={styles.start_shopping}
              onClick={() => {
                toggleAuthModal(AUTH_MODAL_TYPES.SOCIAL_SIGN_IN);
              }}
            >
              {t('button.signIn')}
            </Button>
          ) : (
            <Button
              className={styles.start_shopping}
              onClick={() => {
                toggleAuthModal(AUTH_MODAL_TYPES.SOCIAL_CREATE_ACCOUNT);
              }}
            >
              {t('button.createAccount')}
            </Button>
          )}
        </>
      )}
    </div>
  );
};
