import { GA_CUSTOM_EVENT_ACTION, GA_ECOM_EVENT_ACTION, GaEventList } from '@modules/ga/type';
import {
  addToCartHandler,
  removeFromCartHandler,
} from '@modules/ga/events/ecommerce/add-to-cart/addToCartHandler';
import { viewCartHandler } from '@modules/ga/events/ecommerce/view_cart/viewCartHandler';
import { beginCheckoutHandler } from '@modules/ga/events/ecommerce/begin-checkout/beginCheckoutHandler';
import { shopByHandler } from '@modules/ga/events/custom/shop-by/shopByHandler';
import {
  rwExternalWidgetView,
  rwProductAddToCart,
  rwProductClick,
} from '@modules/ga/events/custom/RWProduct/rwProductHandlers';
import { viewItemListHandler } from '@modules/ga/events/ecommerce/view-item-list/viewItemListHandler';
import { viewItemHandler } from '@modules/ga/events/ecommerce/view-item/viewItemHandler';
import { purchaseHandler } from '@modules/ga/events/ecommerce/purchage/purchaseHandler';
import {
  commonCustomPageViewHandler,
  customCartPageViewHandler,
  customSearchPageViewHandler,
} from '@modules/ga/events/custom/custom-page-view/customPageViewHandler';
import { loginSuccessHandler } from '@modules/ga/events/custom/login-success/loginSuccessHandler';
import { addShippingInfoHandler } from '@modules/ga/events/ecommerce/add-shipping-info/addShippingInfoHandler';
import { expressOffHandler } from '@modules/ga/events/custom/express-off/expressOffHandler';
import { expressOnHandler } from '@modules/ga/events/custom/express-on/expressOnHandler';
import { userSignupSuccessHandler } from '@modules/ga/events/custom/user-signup-success/userSignupSuccessHandler';
import { searchHandler } from '@modules/ga/events/custom/search/searchHandler';
import { refineResultHandler } from '@modules/ga/events/custom/refine-result/refineResultHandler';
import { addPaymentInfoHandler } from '@modules/ga/events/ecommerce/add-payment-info/addPaymentInfoHandler';
import { selectItemHandler } from '@modules/ga/events/ecommerce/select-item/selectItemHandler';
import { changeAddressHandler } from '@modules/ga/events/custom/change-address/changeAddressHandler';
import { categoryDataLoadHandler } from '@modules/ga/events/custom/category/categoryDataLoadHandler';
import { logoutHandler } from '@modules/ga/events/custom/logout/logoutHandler';
import {
  appRedirectClickEventHandler,
  appRedirectDisplayEventHandler,
} from './events/custom/app-redirect/appRedirectEventHandler';
import { selectPaymentEventHandler } from './events/ecommerce/select-payment/selectPaymentHandler';
import { referralLinkEventHandler } from './events/custom/referral-link/referralLinkEventHandler';
import { selectDeliveryAddressHandler } from './events/ecommerce/select-delivery-address/selectDeliveryAddressHandler';

export const GA_EVENT_TYPE = 'GA_EVENT';

export const GAEvents: GaEventList = {
  [GA_ECOM_EVENT_ACTION.ADD_TO_CART]: addToCartHandler,
  [GA_ECOM_EVENT_ACTION.REMOVE_FROM_CART]: removeFromCartHandler,
  [GA_ECOM_EVENT_ACTION.VIEW_CART]: viewCartHandler,
  [GA_ECOM_EVENT_ACTION.BEGIN_CHECKOUT]: beginCheckoutHandler,
  [GA_ECOM_EVENT_ACTION.VIEW_ITEM_LIST]: viewItemListHandler,
  [GA_ECOM_EVENT_ACTION.VIEW_ITEM]: viewItemHandler,
  [GA_ECOM_EVENT_ACTION.SELECT_ITEM]: selectItemHandler,
  [GA_ECOM_EVENT_ACTION.PURCHASE_EVENT]: purchaseHandler,
  [GA_ECOM_EVENT_ACTION.ADD_SHIPPING_INFO]: addShippingInfoHandler,
  [GA_ECOM_EVENT_ACTION.ADD_PAYMENT_INFO]: addPaymentInfoHandler,
  [GA_ECOM_EVENT_ACTION.SELECT_PAYMENT_METHOD]: selectPaymentEventHandler,
  [GA_ECOM_EVENT_ACTION.SELECT_DELIVERY_ADDRESS]: selectDeliveryAddressHandler,
  [GA_CUSTOM_EVENT_ACTION.SHOP_BY]: shopByHandler,
  [GA_CUSTOM_EVENT_ACTION.RW_EXTERNAL_WIDGET_VIEW_GA_EVENT]: rwExternalWidgetView,
  [GA_CUSTOM_EVENT_ACTION.RW_PRODUCT_CLICK]: rwProductClick,
  [GA_CUSTOM_EVENT_ACTION.RW_PRODUCT_ADD_TO_CART]: rwProductAddToCart,
  [GA_CUSTOM_EVENT_ACTION.COMMON_CUSTOM_PAGE_VIEW]: commonCustomPageViewHandler,
  [GA_CUSTOM_EVENT_ACTION.SEARCH_CUSTOM_PAGE_VIEW]: customSearchPageViewHandler,
  [GA_CUSTOM_EVENT_ACTION.CART_CUSTOM_PAGE_VIEW]: customCartPageViewHandler,
  [GA_CUSTOM_EVENT_ACTION.LOGIN_SUCCESS]: loginSuccessHandler,
  [GA_CUSTOM_EVENT_ACTION.USER_SIGNUP_SUCCESS]: userSignupSuccessHandler,
  [GA_CUSTOM_EVENT_ACTION.EXPRESS_ON]: expressOnHandler,
  [GA_CUSTOM_EVENT_ACTION.EXPRESS_OFF]: expressOffHandler,
  [GA_CUSTOM_EVENT_ACTION.SEARCH]: searchHandler,
  [GA_CUSTOM_EVENT_ACTION.REFINE_RESULT]: refineResultHandler,
  [GA_CUSTOM_EVENT_ACTION.CHANGE_ADDRESS]: changeAddressHandler,
  [GA_CUSTOM_EVENT_ACTION.CATEGORY_DATA_LOAD]: categoryDataLoadHandler,
  [GA_CUSTOM_EVENT_ACTION.LOGOUT]: logoutHandler,
  [GA_CUSTOM_EVENT_ACTION.APP_REDIRECT_CLICK_EVENT]: appRedirectClickEventHandler,
  [GA_CUSTOM_EVENT_ACTION.APP_REDIRECT_DISPLAY_EVENT]: appRedirectDisplayEventHandler,
  [GA_CUSTOM_EVENT_ACTION.REFERRAL_LINK]: referralLinkEventHandler,
};
